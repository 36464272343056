import { gql } from "@apollo/client";

export const endUserAttendanceBreak = gql`
  mutation endUserAttendanceBreak(
    $attendanceId: ID!
    $breakEndTime: DateTime!
  ) {
    endUserAttendanceBreak(
      breakEnd: { attendanceId: $attendanceId, breakEndTime: $breakEndTime }
    ) {
      id
      status
      startTime
      endTime
      date
      totalTimeMinutes
    }
  }
`;
