import { ComponentsProps } from "../models";

export const card: ComponentsProps = {
  MuiCard: {
    defaultProps: {
      variant: "outlined",
      color: "inherit",
    },
    styleOverrides: {
      root: (props) => ({
        fontWeight: 600,
        textTransform: "none",
        lineHeight: "20px",
        border: `1px solid ${props.theme.palette.text.primary}`,
      }),
    },
  },
};
