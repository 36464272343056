import { gql } from "@apollo/client";

export const getAreas = gql`
  query getAreas($areasIds: [ID]!, $date: Date) {
    areasByIds(areasIds: $areasIds) {
      areaType
      id
      name
      numberOfAvailableDesks
      meetingRoomTimeStats(date: $date) {
        booked
        total
      }
      equipment {
        category {
          id
          name
        }
        id
        name
      }
      floor {
        id
        floorLabel
        floorNumber
        floorType
        building {
          id
          name
          address
        }
      }
      numberOfAvailableDesksHourlyByAreaType(date: $date) {
        areaType
        freeSlots
      }
    }
  }
`;
