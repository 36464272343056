import { createTheme } from "@mui/material/styles";
import { button } from "./Button";
import { select } from "./Select";
import { card } from "./Card";
import { textField } from "./TextField";
import { datePicker } from "../default/DatePicker";
import { alert } from "./Alert";

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#CED0E3",
    },
    success: {
      main: "#237B4B",
      light: "#E7F2DA",
    },
    error: {
      main: "#FF4387",
      light: "#FCF4F6",
    },
    warning: {
      main: "#FF4387",
    },
    background: {
      paper: "#212121",
    },
  },
  components: {
    ...button,
    ...select,
    ...datePicker,
    ...card,
    ...textField,
    ...alert,
  },
});
