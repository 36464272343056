import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { authResponseUrlKey, isAuthResponseUrl } from "shared/utils/auth";
import { useAuth0 } from "@auth0/auth0-react";
import { authentication } from "@microsoft/teams-js";
import { useTranslation } from "react-i18next";
import LoginLayout from "./LoginLayout";

export function ProtectedRoute() {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
    handleRedirectCallback,
  } = useAuth0();
  const [apolloClient, setApolloClient] = useState<any>();
  const { t } = useTranslation();

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        setApolloClient(
          new ApolloClient({
            link: createHttpLink({
              uri: process.env.REACT_APP_API_ENDPOINT,
              headers: {
                authorization: token ? `Bearer ${token}` : "",
                "content-type": "application/json",
              },
            }),
            cache: new InMemoryCache(),
            connectToDevTools: true,
          }),
        );
      });
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthResponseUrl()) {
      /*
      // Executed inside popup, attempt to complete the login
      handleRedirectCallback().then(() => {
        authentication.notifySuccess();
      });
      */
      localStorage.setItem(authResponseUrlKey, window.location.href);
      window.close();
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated || isAuthResponseUrl()) {
      return () => {};
    }

    const interval = setInterval(() => {
      const callbackUrl = localStorage.getItem(authResponseUrlKey);
      if (callbackUrl) {
        clearInterval(interval);
        localStorage.setItem(authResponseUrlKey, "");
        handleRedirectCallback(callbackUrl);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isAuthenticated, handleRedirectCallback]);

  const handleLogin = async () => {
    await loginWithRedirect({
      async openUrl(url) {
        try {
          authentication.initialize();
          await authentication.authenticate({
            url,
            isExternal: false,
            height: 600,
          });
        } catch (e) {
          // TODO: When the authentication window is closed the `authentication.authenticate` throws an error
          // figure out how to differentiate between real errors and successful authentication
          console.error(e); // eslint-disable-line no-console
        }
      },
    });
  };

  const renderCenteredContent = () => {
    if (isLoading) {
      return <div className="lds-dual-ring" />;
    }

    if (isAuthResponseUrl()) {
      return t(["Profile.Content.Login.popupText"]);
    }

    return <LoginLayout onClick={handleLogin} />;
  };

  if (isAuthenticated) {
    if (apolloClient) {
      return (
        <ApolloProvider client={apolloClient}>
          <Outlet />
        </ApolloProvider>
      );
    }
    return (
      <div className="centered-container">
        <div className="lds-dual-ring" />
      </div>
    );
  }

  return <div className="centered-container">{renderCenteredContent()}</div>;
}
