import { TimeSlot } from "shared/utils/date";
import { State, initialState, Action, ActionType } from "./definitions";

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.RESET: {
      return {
        ...initialState,
      };
    }
    case ActionType.SELECT_DATE: {
      return {
        ...state,
        selectedDate: action.payload as Date,
      };
    }

    case ActionType.SET_UNAVAILABLE_SLOTS: {
      return {
        ...state,
        unavailableSlots: action.payload as number[],
      };
    }

    case ActionType.SET_HOURS_RANGE: {
      const [start, end] = action.payload as TimeSlot;
      return {
        ...state,
        hoursStart: start,
        hoursEnd: end,
      };
    }
    default:
      throw new Error("Unknown action");
  }
}
