import { useMemo, useEffect } from "react";
import { Typography, TextField, Grid } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { DateUtils, TimeSlot } from "shared/utils/date";

interface TimePickersWrapperProps {
  selectedDate?: Date;
  hoursStart?: number;
  hoursEnd?: number;
  onChange?: (start: number, end: number) => void;
  setHourRange?: (a: TimeSlot) => void;
  unavailableSlots?: number[];
  minmax: TimeSlot;
  isDisabled: boolean;
}

export default function TimePickersWrapper({
  selectedDate = new Date(),
  hoursStart = 0,
  hoursEnd = 0,
  onChange,
  setHourRange,
  unavailableSlots = [],
  minmax,
  isDisabled,
}: TimePickersWrapperProps) {
  const { t } = useTranslation();
  const availableHours = useMemo(() => {
    return DateUtils.getAvailableMinMaxHours(minmax, unavailableSlots);
  }, [minmax, unavailableSlots]);

  useEffect(() => {
    const firstAvailableSlot = availableHours[0][0];
    const lastAvailableSlot = availableHours[1][availableHours[1].length - 1];
    const range: TimeSlot = [firstAvailableSlot, lastAvailableSlot];
    setHourRange?.(range);
  }, [availableHours]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="caption" display="block" gutterBottom>
          {t("Time.Slots.from")}
        </Typography>
        <TimePicker
          disabled={isDisabled}
          value={DateUtils.setFullHour(selectedDate, hoursStart)}
          onChange={(value) => {
            if (value) {
              onChange?.(value.getHours(), hoursEnd);
            }
          }}
          renderInput={(params) => <TextField {...params} />}
          views={["hours"]}
          ampm={false}
          shouldDisableTime={(time, clockType) => {
            if (clockType === "hours") {
              return !availableHours[0].includes(time) || time >= hoursEnd;
            }
            return false;
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="caption" display="block" gutterBottom>
          {t("Time.Slots.to")}
        </Typography>
        <TimePicker
          disabled={isDisabled}
          value={DateUtils.setFullHour(selectedDate, hoursEnd)}
          onChange={(value) => {
            if (value) {
              onChange?.(hoursStart, value.getHours());
            }
          }}
          renderInput={(params) => <TextField {...params} />}
          views={["hours"]}
          ampm={false}
          shouldDisableTime={(time, clockType) => {
            if (clockType === "hours") {
              return !availableHours[1].includes(time) || time <= hoursStart;
            }
            return false;
          }}
        />
      </Grid>
    </Grid>
  );
}
