import { useMemo, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { OrganizationHelpers } from "shared/utils/organization";
import { useSharedData } from "shared/state";
import { ToggleCard } from "shared/components/cards/ToggleCard";
import { FirstHalfDay, FullDay, SecondHalfDay } from "shared/icons/Day";

type BookingType = "desk" | "meeting" | "parking";

function joinTime(start: number, end: number): string {
  return `${start} - ${end}h`;
}

const ContentConteainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(150px, 30%))",
  gap: 20,
});

interface PeriodPickerProps {
  bookingType: BookingType;
  unavailableSlots: number[];
  selectedStart: number;
  selectedEnd: number;
  onSelect?: (start: number, end: number) => void;
}

export default function PeriodPicker({
  bookingType,
  unavailableSlots,
  selectedStart,
  selectedEnd,
  onSelect,
}: PeriodPickerProps) {
  const { state } = useSharedData();
  const { t } = useTranslation();
  const isHalfDayEnabled =
    state.myOrganization?.enabledFeatures.includes("halfday_booking");

  const [isFirstHalfEnabled, setIsFirstHalfEnabled] = useState(true);
  const [isFullDayEnabled, setIsFullDayEnabled] = useState(true);
  const [isSecondHalfEnabled, setIsSecondHalfEnabled] = useState(true);

  const orgTimes = useMemo<[number, number, number] | null>(() => {
    if (!state.myOrganization) {
      return null;
    }

    const mid = OrganizationHelpers.getMidDay(state.myOrganization);
    if (mid < 0) {
      return null;
    }

    let [orgStart, orgEnd] = [
      state.myOrganization.workingHoursStart,
      state.myOrganization.workingHoursEnd,
    ];
    if (bookingType === "meeting") {
      [orgStart, orgEnd] = [
        state.myOrganization.meetingRoomHoursStart,
        state.myOrganization.meetingRoomHoursEnd,
      ];
    }

    const [start, end] = [orgStart, orgEnd].map((val) =>
      OrganizationHelpers.parseOrganizationTime((val ?? "").toString()),
    );

    return [start, mid, end];
  }, [state.myOrganization, bookingType]);

  useEffect(() => {
    if (orgTimes) {
      const [start, mid, end] = orgTimes;
      setIsFirstHalfEnabled(
        !unavailableSlots.some((val) => val >= start && val < mid),
      );
      setIsFullDayEnabled(!(unavailableSlots.length > 0));
      setIsSecondHalfEnabled(
        !unavailableSlots.some((val) => val >= mid && val < end),
      );
    } else {
      setIsFirstHalfEnabled(false);
      setIsFullDayEnabled(false);
      setIsSecondHalfEnabled(false);
    }
  }, [orgTimes, unavailableSlots]);

  if (!orgTimes) {
    return null;
  }
  return (
    <ContentConteainer>
      {isFirstHalfEnabled && isHalfDayEnabled && (
        <ToggleCard
          image={<FirstHalfDay />}
          title={t("Time.Slots.selection.button.beforeLunch") as string}
          subtitle={joinTime(orgTimes[0], orgTimes[1])}
          isActive={
            orgTimes[0] === selectedStart && orgTimes[1] === selectedEnd
          }
          onToggle={() => onSelect?.(orgTimes[0], orgTimes[1])}
        />
      )}
      {isFullDayEnabled && (
        <ToggleCard
          image={<FullDay />}
          title={t("Time.Slots.selection.button.fullDay") as string}
          subtitle={joinTime(orgTimes[0], orgTimes[2])}
          isActive={
            orgTimes[0] === selectedStart && orgTimes[2] === selectedEnd
          }
          onToggle={() => onSelect?.(orgTimes[0], orgTimes[2])}
        />
      )}
      {isSecondHalfEnabled && isHalfDayEnabled && (
        <ToggleCard
          image={<SecondHalfDay />}
          title={t("Time.Slots.selection.button.AfterLunch") as string}
          subtitle={joinTime(orgTimes[1], orgTimes[2])}
          isActive={
            orgTimes[1] === selectedStart && orgTimes[2] === selectedEnd
          }
          onToggle={() => onSelect?.(orgTimes[1], orgTimes[2])}
        />
      )}
    </ContentConteainer>
  );
}
