import { useMutation } from "@apollo/client";
import { FavouriteTypeEnum } from "api/schema";
import { removeFavourite } from "gql/mutations/favourites/removeFavourite";

interface GqlData {
  removeFavourite: boolean;
}

interface GqlVars {
  favouriteId: string;
  favouriteType: FavouriteTypeEnum;
}

export function useRemoveFavourite() {
  return useMutation<GqlData, GqlVars>(removeFavourite)[0];
}
