import { BuildingType, FloorType, AreaType } from "api/schema";
import { DateUtils, TimeSlot } from "shared/utils/date";
import { Action, ActionType } from "./definitions";

export namespace Actions {
  export function reset(): Action {
    return {
      type: ActionType.RESET,
    };
  }

  export function selectDate(date?: Date | null): Action {
    return {
      type: ActionType.SELECT_DATE,
      payload: DateUtils.toFullHour(date ?? new Date()),
    };
  }

  export function selectBuilding(building?: BuildingType): Action {
    return {
      type: ActionType.SELECT_BUILDING,
      payload: building,
    };
  }

  export function setFloors(floors?: FloorType[]): Action {
    return {
      type: ActionType.SET_FLOORS,
      payload: floors,
    };
  }

  export function selectFloor(floor?: FloorType): Action {
    return {
      type: ActionType.SELECT_FLOOR,
      payload: floor,
    };
  }

  export function setTelephoneBoxes(areas?: AreaType[]): Action {
    return {
      type: ActionType.SET_TELEPHONE_BOXES,
      payload: areas,
    };
  }

  export function selectTelephoneBox(area?: AreaType): Action {
    return {
      type: ActionType.SELECT_TELEPHONE_BOX,
      payload: area,
    };
  }

  export function setUnavailableSlots(arr?: number[]): Action {
    return {
      type: ActionType.SET_UNAVAILABLE_SLOTS,
      payload: Array.isArray(arr) ? arr : [],
    };
  }

  export function setHoursRange(value?: TimeSlot): Action {
    return {
      type: ActionType.SET_HOURS_RANGE,
      payload: value ?? [0, 0],
    };
  }
}
