import { gql } from "@apollo/client";

export const endUserAttendance = gql`
  mutation endUserAttendance($attendanceId: ID!, $endTime: DateTime!) {
    endUserAttendance(
      attendanceEnd: { attendanceId: $attendanceId, endTime: $endTime }
    ) {
      id
      status
      startTime
      endTime
      date
      totalTimeMinutes
    }
  }
`;
