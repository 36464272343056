import { startOfToday } from "date-fns";

export interface State {
  selectedDate: Date;
  hoursStart?: number;
  hoursEnd?: number;
  unavailableSlots: number[];
}

export const initialState: State = {
  selectedDate: startOfToday(),
  unavailableSlots: [],
};

export enum ActionType {
  RESET,
  SELECT_DATE,
  SET_AREAS,
  SET_HOURS_RANGE,
  SET_UNAVAILABLE_SLOTS,
}

export interface Action {
  type: ActionType;
  payload?: unknown;
}
