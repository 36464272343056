import { format, isFuture, isToday } from "date-fns";

function applyFormat(date: Date | string, template: string): string {
  return format(typeof date === "string" ? new Date(date) : date, template);
}

export type TimeSlot = [number, number];

function getDayTimeSlots(): TimeSlot[] {
  const slots: TimeSlot[] = [];
  for (let i = 0; i < 23; i++) {
    slots.push([i, i + 1]);
  }
  slots.push([23, 0]);
  return slots;
}

export namespace DateUtils {
  export const DATE_FORMAT = "yyyy-MM-dd";
  export const DAY_MONTH_FORMAT = "dd.MM";
  export const DAY_FORMAT = "EEE, MMM do";
  export const HOUR_MINUTE_FORMAT = "HH:mm";

  export function formatDate(date: Date | string): string {
    return applyFormat(date, DATE_FORMAT);
  }

  export function formatDayMonth(date: Date | string): string {
    return applyFormat(date, DAY_MONTH_FORMAT);
  }

  export function formatDay(date: Date | string): string {
    return applyFormat(date, DAY_FORMAT);
  }

  export function formatDayAndTime(start: Date, end: Date): string {
    return `${applyFormat(
      start,
      DAY_FORMAT,
    )}, ${start.getUTCHours()}h - ${end.getUTCHours()}h`;
  }

  export function formatHourAndMinute(date: Date | string): string {
    return applyFormat(date, HOUR_MINUTE_FORMAT);
  }

  export interface DateRange {
    start: Date;
    end: Date;
  }

  export function isPastDay(date: Date | string): boolean {
    const day = typeof date === "string" ? new Date(date) : date;
    return !(isFuture(day) || isToday(day));
  }

  export function toFullHour(date: Date): Date {
    date.setMinutes(0, 0, 0);
    return date;
  }

  export function setFullUTCHour(
    date: Date,
    hour: number,
    minutes: number = 0,
    seconds: number = 0,
  ): Date {
    const utcDate = new Date(date);
    utcDate.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    utcDate.setUTCHours(hour, minutes, seconds, 0);
    return utcDate;
  }

  export function setFullHour(date: Date, hour: number): Date {
    const dateCopy = date ? new Date(date) : new Date();
    dateCopy.setHours(hour, 0, 0, 0);
    return dateCopy;
  }

  export function getAvailableMinMaxHours(
    minmax: TimeSlot,
    unavailableHours: number[] = [],
  ): [number[], number[]] {
    const unavailable = new Set(unavailableHours);
    const availablePairs = getDayTimeSlots().filter(
      ([start]) =>
        !unavailable.has(start) && start >= minmax[0] && start < minmax[1],
    );
    const availableStarts = Array.from(
      new Set(availablePairs.map((slot) => slot[0])),
    );
    const availableEnds = Array.from(
      new Set(availablePairs.map((slot) => slot[1])),
    );
    return [availableStarts, availableEnds];
  }

  export function convertMinutesToHoursMinutes(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (hours && remainingMinutes) return `${hours}h ${remainingMinutes}min`;
    if (!hours && remainingMinutes) return `${remainingMinutes}min`;
    return `${hours}h`;
  }
}
