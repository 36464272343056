import { AreaType, FloorType, BuildingType, DeskType } from "api/schema";
import { startOfToday } from "date-fns";

export interface State {
  selectedDate: Date;
  selectedBuilding?: BuildingType;
  floors: FloorType[];
  selectedFloor?: FloorType;
  areas: AreaType[];
  selectedArea?: AreaType;
  desks: DeskType[];
  selectedDesk?: DeskType;
  hoursStart?: number;
  hoursEnd?: number;
  unavailableSlots: number[];
  isFavDesk: boolean;
}

export const initialState: State = {
  selectedDate: startOfToday(),
  floors: [],
  areas: [],
  desks: [],
  unavailableSlots: [],
  isFavDesk: false,
};

export enum ActionType {
  RESET,
  SELECT_BUILDING,
  SELECT_DATE,
  SET_FLOORS,
  SELECT_FLOOR,
  SET_AREAS,
  SELECT_AREA,
  SET_DESKS,
  SELECT_DESK,
  SET_HOURS_RANGE,
  SET_UNAVAILABLE_SLOTS,
  SET_IS_FAVOURITE_DESK,
}

export interface Action {
  type: ActionType;
  payload?: unknown;
}
