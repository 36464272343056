import { gql } from "@apollo/client";

export const getMyOrganization = gql`
  query getMyOrganization($language: LanguageEnumType) {
    myOrganization {
      backgroundColor
      bookingRange
      id
      iconColor
      logo
      name
      workingDaysStart
      workingDaysEnd
      workingHoursStart
      workingHoursEnd
      meetingRoomHoursStart
      meetingRoomHoursEnd
      midDayHour
      enabledFeatures
      cateringPreparationTime
      equipmentPreparationTime
      departments {
        id
        name
      }
      translations(language: $language) {
        id
        key
        value
      }
    }
  }
`;
