import { useMutation } from "@apollo/client";
import { endUserAttendanceBreak } from "gql/mutations/attendance/endUserAttendanceBreak";
import { UserAttendanceType } from "api/schema";

interface GqlData {
  endUserAttendanceBreak: UserAttendanceType;
}

interface GqlVars {
  attendanceId: string;
  breakEndTime: string;
}

export function useEndtUserAttendanceBreak() {
  return useMutation<GqlData, GqlVars>(endUserAttendanceBreak)[0];
}
