import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  styled,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSharedData, ModalType } from "shared/state";
import { BookingStatusUtil } from "shared/utils/booking-status";
import { DateUtils } from "shared/utils/date";
import { getItemNumber } from "shared/utils/dropdown";
import { useUnavailableTimePeriods } from "api/queries/bookings/unavailableTimePeriods";
import { useGetColleague } from "api/queries/colleagues/getColleague";
import {
  AreaType,
  BookingType,
  DeskType,
  ParkingType,
  ResourceBookingStatus,
  ColleagueType,
  UnavailableTimePeriodsType,
} from "api/schema";
import LizLogo from "assets/icons/LizLogo.svg";
import DialogHeader from "./components/DialogHeader";
import DialogWrapper from "./components/DialogWrapper";

interface ModalBody {
  itemName: string;
  floorLabel: string;
  equipment?: string[];
  bookedSlots?: BookingType[];
}

interface ColleagueTimeSlotProps {
  slot: UnavailableTimePeriodsType;
}

interface ModalConfig {
  heading: string;
  body: ModalBody;
  hasAction: boolean;
  buttonText?: string;
  className: string;
}

const CenteredContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexGrow: 1,
  flexDirection: "column",
  gap: 10,
});

const StyledCard = styled("div")({
  minWidth: 190,
  padding: "1rem",
  borderRadius: "3px",
  fontSize: "12px",
  lineHeight: "16px",
});

function ColleagueTimeSlot({ slot }: ColleagueTimeSlotProps) {
  const [colleagueInfo, setColleagueInfo] = useState<ColleagueType | null>();
  const startDate = new Date(slot.start);
  const endDate = new Date(slot.end);

  useGetColleague(
    (data) => {
      setColleagueInfo(data);
    },
    {
      userID: slot.userId[0],
      skip: slot.userId.length <= 0,
    },
  );

  return (
    <Typography variant="subtitle1" fontSize={14}>
      {DateUtils.formatDayAndTime(startDate, endDate)}
      {colleagueInfo?.name && `, ${colleagueInfo?.name}`}
    </Typography>
  );
}

export function BookingInfoModal() {
  const { state, dispatch, Actions } = useSharedData();
  const { t } = useTranslation();

  const [timePeriods, setTimePeriods] = useState<UnavailableTimePeriodsType[]>(
    [],
  );
  const modalItem = state.modalItem as
    | (AreaType & DeskType & ParkingType & { myBookings: BookingType[] })
    | undefined;
  const clickedObject = modalItem?.areaType;

  function openBookingModal(): void {
    let bookingModal: ModalType | undefined;
    switch (clickedObject) {
      case "room":
        bookingModal = ModalType.BOOK_DESK;
        break;
      case "meeting_room":
        bookingModal = ModalType.BOOK_MEETING_ROOM;
        break;
      case "parking":
        bookingModal = ModalType.BOOK_PARKING_SPOT;
        break;
      case "free_spaces_room":
        bookingModal = ModalType.BOOK_FREE_SPACE;
        break;
      case "telefon_box":
        bookingModal = ModalType.BOOK_TELEPHONE_BOX;
        break;
      default: {
        bookingModal = undefined;
        break;
      }
    }
    dispatch(Actions.openModal(bookingModal));
  }

  const modalConfig = useMemo<ModalConfig>(() => {
    let status: ResourceBookingStatus = "FREE";
    const body: ModalBody = {
      itemName: modalItem?.id ?? "",
      floorLabel: modalItem?.floor?.floorLabel ?? "",
      bookedSlots: modalItem?.myBookings.filter(
        (booking) => booking?.desk?.id === modalItem?.id,
      ),
    };
    if (modalItem) {
      switch (clickedObject) {
        case "room":
          status = BookingStatusUtil.getDeskStatus(
            modalItem,
            modalItem?.myBookings ?? [],
          );
          body.itemName = getItemNumber(modalItem);
          body.bookedSlots = modalItem?.myBookings.filter(
            (booking) => booking?.desk?.id === modalItem?.id,
          );
          break;
        case "meeting_room":
          status = BookingStatusUtil.getMeetingRoomStatus(
            modalItem,
            modalItem?.myBookings ?? [],
          );
          body.bookedSlots = modalItem?.myBookings.filter(
            (booking) =>
              booking.area?.areaType === "meeting_room" &&
              booking.area.id === modalItem.id,
          );
          body.itemName = modalItem.name || modalItem.id;
          break;
        case "parking":
          status = BookingStatusUtil.getParkingStatus(
            modalItem,
            modalItem?.myBookings ?? [],
          );
          body.bookedSlots = modalItem?.myBookings.filter(
            (booking) =>
              booking.area?.areaType === "parking" &&
              booking.area.id === modalItem.areaId,
          );
          body.itemName = getItemNumber(modalItem);
          break;
        case "telefon_box":
          status = BookingStatusUtil.getTelBoxStatus(
            modalItem,
            modalItem?.myBookings ?? [],
          );
          body.bookedSlots = modalItem?.myBookings.filter(
            (booking) =>
              booking.area?.areaType === "telefon_box" &&
              booking.area?.id === modalItem.id,
          );
          break;
        case "free_spaces_room":
          status = BookingStatusUtil.getFreeSpaceStatus(
            modalItem,
            modalItem?.myBookings ?? [],
          );
          body.bookedSlots = modalItem?.myBookings.filter(
            (booking) =>
              booking.area?.areaType === "free_spaces_room" &&
              booking.area.id === modalItem.id,
          );
          break;
        default:
          break;
      }
    }
    const className = BookingStatusUtil.getStatusColor(status);
    switch (status) {
      case "BLOCKED":
        return {
          heading: t("AvailabilityInfos.Status.Blocked"),
          hasAction: false,
          className,
          body,
        };
      case "SELF_BOOKED":
        return {
          heading: t("AvailabilityInfos.Status.Booked"),
          hasAction: true,
          buttonText: t("Bookings.Day.book.more") as string,
          className,
          body,
        };
      case "BOOKED":
        return {
          heading: t("AvailabilityInfos.Status.BookedByColleague"),
          hasAction: true,
          className,
          body,
        };
      default:
        return {
          heading: t("AvailabilityInfos.Status.Available"),
          hasAction: true,
          className,
          body,
        };
    }
  }, [state]);

  useUnavailableTimePeriods(
    (data) => {
      setTimePeriods(data);
    },
    {
      date: state.modalInitialDate,
      areaId: (state?.modalItem as DeskType)?.areaId ?? "",
      deskId: (state?.modalItem as DeskType)?.id ?? "",
      skip:
        BookingStatusUtil.getDeskStatus(
          state.modalItem as DeskType,
          modalItem?.myBookings ?? [],
        ) !== "BOOKED",
    },
  );

  const showBookingDetails = (slots: UnavailableTimePeriodsType[]) => {
    if (slots.length > 0) {
      return (
        <Box textAlign="center">
          {slots.map((slot) => {
            return <ColleagueTimeSlot slot={slot} />;
          })}
        </Box>
      );
    }
    return null;
  };

  return (
    <DialogWrapper open>
      <DialogHeader
        icon={LizLogo}
        title={t("Home.ActiveBooking.Booking.MakeBooking")}
        subheader={t("Home.ActiveBooking.Booking.Popup.SubTitle")}
      />
      <DialogContent>
        <CenteredContainer>
          <>
            <Typography variant="body1" fontSize={16} fontWeight={500}>
              {modalConfig.heading}
            </Typography>
            {showBookingDetails(timePeriods)}
            <Box textAlign="center">
              {Boolean(modalConfig.body.bookedSlots?.length) &&
                modalConfig.body.bookedSlots?.map((slot) => {
                  const startDate = new Date(slot.start);
                  const endDate = new Date(slot.end);
                  return (
                    <Typography variant="subtitle1" fontSize={14} key={slot.id}>
                      {DateUtils.formatDayAndTime(startDate, endDate)}
                    </Typography>
                  );
                })}
            </Box>
            {state.modalItem && (
              <StyledCard className={modalConfig.className}>
                <Typography variant="subtitle2" fontSize={18}>
                  {modalConfig.body.itemName}
                </Typography>
                <Typography variant="body2">
                  {`${state.selectedBuilding?.name}, ${modalConfig.body.floorLabel}`}
                </Typography>
                {!!modalConfig.body?.equipment?.length && (
                  <Typography variant="subtitle2" paddingTop={2}>
                    {t(["Home.Book.Equipping.Order.Title"])}:&nbsp;
                    {modalConfig.body.equipment.join(", ")}
                  </Typography>
                )}
              </StyledCard>
            )}
          </>
        </CenteredContainer>
        {modalConfig.hasAction && (
          <DialogActions>
            <Button color="primary" onClick={() => openBookingModal()}>
              {modalConfig.buttonText ?? t("Home.Favourites.book")}
            </Button>
          </DialogActions>
        )}
      </DialogContent>
    </DialogWrapper>
  );
}
