import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { getUnavailableTimePeriods } from "gql/queries/bookings/unavailableTimePeriods";
import { UnavailableTimePeriodsType } from "api/schema";
import { DateUtils } from "shared/utils/date";

interface GqlData {
  unavailableTimePeriods: UnavailableTimePeriodsType[];
}

interface GqlVars {
  date: string;
  areaId?: string;
  deskId?: string;
  homeOffice?: boolean;
  parkingId?: string;
  travelDay?: boolean;
}

interface QueryParams {
  date: Date;
  areaId?: string;
  deskId?: string;
  homeOffice?: boolean;
  parkingId?: string;
  travelDay?: boolean;
  skip?: boolean;
}

type OnSettled = (data: UnavailableTimePeriodsType[]) => void;

export function useUnavailableTimePeriods(
  onSettled?: OnSettled,
  params?: QueryParams,
) {
  const payload: Partial<GqlVars> = {
    date: DateUtils.formatDate(params?.date ?? new Date()),
  };

  const keys: Array<keyof QueryParams> = [
    "areaId",
    "deskId",
    "homeOffice",
    "parkingId",
    "travelDay",
  ];

  keys.forEach((k) => {
    if (typeof params?.[k] !== "undefined") {
      Object.assign(payload, {
        [k]: params[k],
      } as Partial<GqlVars>);
    }
  });

  const q = useQuery<GqlData, GqlVars>(getUnavailableTimePeriods, {
    variables: payload as any,
    skip: params?.skip,
  });

  useEffect(() => {
    if (q.data?.unavailableTimePeriods) {
      onSettled?.(q.data?.unavailableTimePeriods);
    }
  }, [q.data]);

  return q;
}
