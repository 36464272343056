import { useMemo, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { StaticTimePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { setMinutes, setSeconds, formatISO } from "date-fns";
import { useSharedData } from "shared/state";
import { OrganizationHelpers } from "shared/utils/organization";
import { useStartUserAttendance } from "api/mutations/userAttendance/startUserAttendance";
import { useStartUserAttendanceBreak } from "api/mutations/userAttendance/startUserAttendanceBreak";
import { useEndtUserAttendanceBreak } from "api/mutations/userAttendance/endUserAttendanceBreak";
import { useUserAttendances } from "api/queries/userAttendances/userAttendances";
import TimeTracking from "assets/icons/TimeTracking.svg";
import DialogHeader from "./components/DialogHeader";
import DialogWrapper from "./components/DialogWrapper";

export function TimeTrackingModal() {
  const { state, dispatch, Actions } = useSharedData();
  const today = new Date();
  const [selectedTime, setSelectedTime] = useState(
    setSeconds(setMinutes(today, Math.floor(today.getMinutes() / 5) * 5), 0),
  );
  const { t } = useTranslation();

  const status = OrganizationHelpers.getAttendanceStatus(state.userAttendances);
  const inProgress = state.userAttendances.find(
    (att) => att.status === "IN_PROGRESS",
  );
  const onBreak = state.userAttendances.find(
    (att) => att.status === "ON_BREAK",
  );

  const startAttendance = useStartUserAttendance();
  const startAttendanceBreak = useStartUserAttendanceBreak();
  const endAttendanceBreak = useEndtUserAttendanceBreak();

  function getTrackingLabel() {
    switch (status) {
      case "ON_BREAK":
        return t("Home.Attendance.Layer.BreakEnd.Title");
      case "IN_PROGRESS":
        return t("Home.Attendance.Layer.BreakStart.Title");
      default:
        return t("Home.Attendance.Layer.Start.Title");
    }
  }

  const { refetch: refetchAttendace } = useUserAttendances((data) => {
    dispatch(Actions.setAttendances(data));
  });

  function attendanceAction() {
    if (status === "OPEN") {
      startAttendance({
        variables: {
          startTime: formatISO(selectedTime),
        },
      })
        .then(() => {
          refetchAttendace();
          dispatch(Actions.openModal());
        })
        .catch((error) => {
          dispatch(
            Actions.notifyError(error?.message || t(["Errors.error_title"])),
          );
        });
    } else if (status === "IN_PROGRESS") {
      startAttendanceBreak({
        variables: {
          attendanceId: inProgress?.id ?? "",
          breakStartTime: formatISO(selectedTime),
        },
      })
        .then(() => {
          refetchAttendace();
          dispatch(Actions.openModal());
        })
        .catch((error) => {
          dispatch(
            Actions.notifyError(error?.message || t(["Errors.error_title"])),
          );
        });
    } else if (status === "ON_BREAK") {
      endAttendanceBreak({
        variables: {
          attendanceId: onBreak?.id ?? "",
          breakEndTime: formatISO(selectedTime),
        },
      })
        .then(() => {
          refetchAttendace();
          dispatch(Actions.openModal());
        })
        .catch((error) => {
          dispatch(
            Actions.notifyError(error?.message || t(["Errors.error_title"])),
          );
        });
    }
  }

  const isDisabled = useMemo(() => {
    const { userAttendances } = state;
    if (inProgress && !inProgress.attendanceBreaks.length) {
      return new Date(inProgress?.startTime).getTime() > selectedTime.getTime();
    }
    if (inProgress) {
      const sortedBreaks = [...inProgress.attendanceBreaks].sort(
        (a, b) =>
          new Date(b?.endTime).getTime() - new Date(a?.endTime).getTime(),
      );
      return (
        new Date(sortedBreaks[0]?.endTime).getTime() > selectedTime.getTime()
      );
    }
    if (onBreak) {
      const sortedBreaks = [...onBreak.attendanceBreaks].sort(
        (a, b) =>
          new Date(b?.startTime).getTime() - new Date(a?.startTime).getTime(),
      );
      return (
        new Date(sortedBreaks[0]?.startTime).getTime() > selectedTime.getTime()
      );
    }
    if (userAttendances.every((att) => att.status === "OPEN")) {
      return (
        new Date(userAttendances[0]?.endTime).getTime() > selectedTime.getTime()
      );
    }
    return false;
  }, [state, selectedTime, inProgress, onBreak]);

  return (
    <DialogWrapper open>
      <DialogHeader
        icon={TimeTracking}
        title={t("Home.Attendance.Title")}
        subheader={t("Home.ActiveBooking.Booking.Popup.SubTitle")}
      />
      <DialogContent>
        <StaticTimePicker
          label={getTrackingLabel()}
          value={selectedTime}
          onChange={(value) => {
            if (value) {
              setSelectedTime(value);
            }
          }}
          renderInput={(params) => <TextField {...params} />}
          views={["hours", "minutes"]}
          minutesStep={5}
          ampm={false}
          componentsProps={{ actionBar: { actions: [] } }}
          shouldDisableTime={(time, clockType) => {
            if (clockType === "hours") {
              return (
                time <
                  OrganizationHelpers.parseOrganizationTime(
                    (state.myOrganization?.workingHoursStart ?? "").toString(),
                  ) ||
                time >
                  OrganizationHelpers.parseOrganizationTime(
                    (state.myOrganization?.workingHoursEnd ?? "").toString(),
                  ) ||
                time > today.getHours()
              );
            }
            if (
              clockType === "minutes" &&
              selectedTime.getHours() === today.getHours()
            ) {
              return time > today.getMinutes();
            }
            return false;
          }}
        />
        {isDisabled && (
          <Typography color="error" paddingTop={2} fontSize={14}>
            {t("Home.Attendance.Layer.ErrorTimeOverlapping")}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isDisabled}
          onClick={() => attendanceAction()}
        >
          {t("Home.Attendance.Button.Save")}
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
}
