import { styled } from "@mui/material/styles";

export const PageContainer = styled("article")((props) => ({
  padding: "0 20px",
  overflow: "auto",
  backgroundColor: props.theme.palette.background.default,
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));
