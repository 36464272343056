import { useMemo, useReducer, useEffect } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DateUtils, TimeSlot } from "shared/utils/date";
import { OrganizationHelpers } from "shared/utils/organization";
import BookParkingSpot from "assets/icons/BookParkingSpot.svg";
import { AreaType, ParkingType } from "api/schema";
import { useMyActiveBookings } from "api/queries/bookings/myActiveBookings";
import { useBuildingStructureForParking } from "api/queries/building/buildingStructureForParking";
import { useAreaParkings } from "api/queries/areas/areasParkings";
import { useUnavailableTimePeriods } from "api/queries/bookings/unavailableTimePeriods";
import { useCreateParkingBooking } from "api/mutations/booking/createParkingBooking";
import { useSharedData } from "shared/state";
import { filterActiveBookings } from "shared/utils/filter-booking";
import { BookDeskContext, initialState, reducer, Actions } from "./state";
import {
  DialogHeader,
  TimePickers,
  DatePicker,
  DialogWrapper,
  ErrorMessage,
  PeriodPicker,
  SelectOptions,
} from "../components";
import {
  getBuildingOptions,
  getFloorOptions,
  getParkingSpots,
} from "../../../utils/dropdown";

export default function BookParkingSpotModal() {
  const shared = useSharedData();
  const modalItem = shared?.state?.modalItem as
    | (AreaType & ParkingType)
    | undefined;
  const [state, dispatch] = useReducer(reducer, initialState);
  const context = useMemo(() => ({ state, dispatch, Actions }), [state]);
  const hourlyBookingFlag =
    shared.state.myOrganization?.enabledFeatures.includes("hourly_booking");
  const { t } = useTranslation();

  const createBooking = useCreateParkingBooking();

  const minmax = useMemo<TimeSlot>(() => {
    if (shared.state.myOrganization) {
      return [
        shared.state.myOrganization.workingHoursStart,
        shared.state.myOrganization.workingHoursEnd,
      ].map((value) =>
        OrganizationHelpers.parseOrganizationTime(value.toString()),
      ) as TimeSlot;
    }
    return [0, 0];
  }, [shared.state.myOrganization]);

  useEffect(() => {
    dispatch(Actions.setHoursRange(minmax));
  }, [minmax]);

  useEffect(() => {
    dispatch(Actions.selectDate(shared.state.modalInitialDate));
  }, [shared.state.modalInitialDate]);

  useEffect(() => {
    if (shared.state.selectedBuilding) {
      if (!state.selectedBuilding) {
        dispatch(Actions.selectBuilding(shared.state.selectedBuilding));
      } else if (typeof modalItem !== "undefined") {
        const building = shared.state.buildings.find(
          (b) => b.id === modalItem?.floor?.building?.id,
        );
        if (state.selectedBuilding.id !== building?.id) {
          dispatch(Actions.selectBuilding(building));
        }
      }
    }
  }, [shared.state.selectedBuilding, modalItem]);

  const { loading: loadingFloors } = useBuildingStructureForParking(
    (data) => {
      dispatch(
        Actions.setFloors(
          data?.floors.filter((e) => e.floorType === "parking"),
        ),
      );
    },
    {
      building: state.selectedBuilding,
      date: state.selectedDate,
    },
  );

  useEffect(() => {
    if (state.floors) {
      if (typeof modalItem !== "undefined") {
        const floor = state.floors.find((f) => f.id === modalItem?.floor?.id);
        if (state.selectedFloor?.id !== floor?.id) {
          dispatch(Actions.selectFloor(floor));
        }
      }
    }
  }, [state.floors, modalItem]);

  useEffect(() => {
    if (state.selectedFloor) {
      dispatch(
        Actions.setAreas(
          (state.selectedFloor?.areas || []).filter(
            (e) => e.areaType === "parking",
          ),
        ),
      );
    }
  }, [state.selectedFloor]);

  useEffect(() => {
    if (state.areas) {
      if (typeof modalItem !== "undefined") {
        const area = state.areas.find((a) => a.id === modalItem?.areaId);
        if (state.selectedArea?.id !== area?.id) {
          dispatch(Actions.selectArea(area));
        }
      }
    }
  }, [state.areas, modalItem]);

  const { loading: loadingParkingSpots } = useAreaParkings(
    (data) => {
      dispatch(Actions.setParkingSpots(data));
    },
    {
      areas: state?.selectedFloor?.areas[0]
        ? [state?.selectedFloor?.areas[0]]
        : [],
      date: DateUtils.setFullUTCHour(state.selectedDate, 0),
    },
  );

  useEffect(() => {
    if (state.parkingSpots) {
      if (typeof modalItem !== "undefined") {
        const parking = state.parkingSpots.find((p) => p.id === modalItem?.id);
        if (state.selectedParkingSpot?.id !== parking?.id) {
          dispatch(Actions.selectParkingSpot(parking));
        }
      }
    }
  }, [state.parkingSpots, modalItem, state.selectedParkingSpot?.id]);

  const isFloorDisabled = !(state.selectedDate && state.selectedBuilding);
  const isTimePeriodDisabled = !state.selectedParkingSpot;

  const { refetch: refetchTimePeriods } = useUnavailableTimePeriods(
    (data) => {
      const combinedData = [
        ...(shared.state.myBookedSlots ?? []),
        ...OrganizationHelpers.getUnavailableTimePeriods(data),
      ].sort((a, b) => a - b);

      const uniqueSlots = Array.from(new Set(combinedData));
      dispatch(Actions.setUnavailableSlots(uniqueSlots));
    },
    {
      date: state.selectedDate,
      areaId: "",
      parkingId: state.selectedParkingSpot?.id ?? "",
      skip: isTimePeriodDisabled || !Array.isArray(shared.state.myBookedSlots),
    },
  );

  const { refetch: refetchActiveBookings } = useMyActiveBookings(
    (data) => {
      const filteredByParking = filterActiveBookings(data, "parking");
      shared.dispatch(shared.Actions.setMyBookedSlots(filteredByParking));
    },
    {
      start: state.selectedDate,
      end: state.selectedDate,
    },
  );

  useEffect(() => {
    refetchActiveBookings().then(() => {
      shared.dispatch(shared.Actions.setLoading(false));
    });
  }, [state.selectedDate]);

  useEffect(() => {
    refetchTimePeriods();
  }, [state.unavailableSlots]);

  return (
    <BookDeskContext.Provider value={context}>
      <DialogWrapper open>
        <DialogHeader
          icon={BookParkingSpot}
          title={t("Home.ActiveBooking.Booking.Popup.Parking.Title")}
          subheader={t("Home.ActiveBooking.Booking.Popup.SubTitle")}
        />
        <DialogContent>
          <DatePicker
            initialValue={state.selectedDate}
            onChange={(value) => dispatch(Actions.selectDate(value))}
          />

          <SelectOptions
            placeholder={t("Home.Buildings.Select") as string}
            notAvailablePlaceholder={t("Home.Buildings.NotAvailable") as string}
            state={shared.state.buildings}
            value={state.selectedBuilding?.id ?? ""}
            setValue={(val) => dispatch(Actions.selectBuilding(val))}
            getOptions={() => getBuildingOptions(shared.state)}
          />

          <SelectOptions
            placeholder={t("Filter.Floors.Select") as string}
            notAvailablePlaceholder={t("Filter.Floors.NoFloors") as string}
            disabled={isFloorDisabled}
            state={state.floors}
            isLoading={loadingFloors}
            value={state?.selectedFloor?.id ?? ""}
            setValue={(val) => dispatch(Actions.selectFloor(val))}
            getOptions={() => getFloorOptions(state)}
          />

          <SelectOptions
            placeholder={t("Filter.Parking.Select") as string}
            notAvailablePlaceholder={t("Filter.Parking.NoSpots") as string}
            disabled={!state.selectedFloor}
            isLoading={loadingParkingSpots}
            state={state.parkingSpots}
            value={state?.selectedParkingSpot?.id ?? ""}
            setValue={(val) => dispatch(Actions.selectParkingSpot(val))}
            getOptions={() => getParkingSpots(state)}
          />

          <Typography variant="caption" display="block" gutterBottom>
            {t(["Time.Slots.selection"])}
          </Typography>
          <PeriodPicker
            bookingType="parking"
            unavailableSlots={state.unavailableSlots}
            selectedStart={state.hoursStart ?? 0}
            selectedEnd={state.hoursEnd ?? 0}
            onSelect={(start, end) => {
              dispatch(Actions.setHoursRange([start, end]));
            }}
          />
          {hourlyBookingFlag && (
            <TimePickers
              isDisabled={isTimePeriodDisabled}
              selectedDate={state.selectedDate}
              hoursStart={state.hoursStart}
              hoursEnd={state.hoursEnd}
              onChange={(start, end) => {
                dispatch(Actions.setHoursRange([start, end]));
              }}
              setHourRange={(timeSlot) =>
                dispatch(Actions.setHoursRange(timeSlot))
              }
              unavailableSlots={state.unavailableSlots}
              minmax={minmax}
            />
          )}
          {state.hoursStart === state.hoursEnd && <ErrorMessage />}
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            disabled={
              !state.selectedParkingSpot?.id ||
              state.hoursStart === state.hoursEnd
            }
            onClick={() => {
              createBooking({
                variables: {
                  booking: {
                    areaId: state.selectedFloor?.areas[0].id ?? "",
                    parkingId: state.selectedParkingSpot?.id ?? "",
                    start: DateUtils.setFullUTCHour(
                      state.selectedDate,
                      state.hoursStart ?? 0,
                    ),
                    end: DateUtils.setFullUTCHour(
                      state.selectedDate,
                      state.hoursEnd ?? 0,
                    ),
                    comment: "",
                  },
                },
              })
                .then(() => {
                  shared.dispatch(shared.Actions.setLoading(true));
                  shared.dispatch(
                    shared.Actions.notifySuccess(
                      t(["Home.ActiveBooking.Booking.title"]),
                    ),
                  );
                })
                .catch((error) => {
                  shared.dispatch(
                    shared.Actions.notifyError(
                      error?.message || t(["Errors.error_title"]),
                    ),
                  );
                });
              shared.dispatch(shared.Actions.openModal());
            }}
          >
            {t(["Routes.Home.Book.Confirm"])}
          </Button>
        </DialogActions>
      </DialogWrapper>
    </BookDeskContext.Provider>
  );
}
