import { gql } from "@apollo/client";

export const getColleague = gql`
  query getColleague($userID: ID!) {
    colleagues(userId: $userID) {
      id
      email
      name
      avatar
      phoneNumber
      department {
        id
        name
      }
      visibleForColleagues
      licensePlate
    }
  }
`;
