import { useState, useEffect } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSharedData } from "shared/state";
import { filterActiveBookings } from "shared/utils/filter-booking";
import { useMyActiveBookings } from "api/queries/bookings/myActiveBookings";
import { useCreateVacationBooking } from "api/mutations/booking/createVacationBooking";
import { DateUtils } from "shared/utils/date";
import { OrganizationHelpers } from "shared/utils/organization";
import Vacation from "assets/icons/Vacation.svg";
import DialogHeader from "./components/DialogHeader";
import DialogWrapper from "./components/DialogWrapper";
import DatePicker from "./components/DatePicker";

export function VacationModal() {
  const { state, dispatch, Actions } = useSharedData();
  const { t } = useTranslation();

  const [unavailableSlots, setUnavailableSlots] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<Date>(
    DateUtils.setFullUTCHour(
      state.modalInitialDate,
      OrganizationHelpers.getWorkingHoursStart(
        state.myOrganization,
        state.modalInitialDate,
      ).getHours(),
    ),
  );
  const [endDate, setEndDate] = useState<Date>(
    DateUtils.setFullUTCHour(
      state.modalInitialDate,
      OrganizationHelpers.getWorkingHoursEnd(
        state.myOrganization,
        state.modalInitialDate,
      ).getHours(),
    ),
  );

  const { refetch: refetchActiveBookings } = useMyActiveBookings(
    (data) => {
      const filteredByVacation = filterActiveBookings(data, "vacation");
      setUnavailableSlots(filteredByVacation);
    },
    {
      start: startDate,
      end: endDate,
    },
  );

  useEffect(() => {
    refetchActiveBookings().then(() => {
      dispatch(Actions.setLoading(false));
    });
  }, [startDate, endDate]);

  const createBooking = useCreateVacationBooking();

  return (
    <DialogWrapper open>
      <DialogHeader
        icon={Vacation}
        title={t("Home.ActiveBookings.Booking.title.vacation")}
        subheader={t("Home.ActiveBooking.Booking.Popup.SubTitle")}
      />
      <DialogContent>
        <DatePicker
          caption={t("Home.Book.Vacation.Today") as string}
          initialValue={startDate}
          onChange={(value) => {
            const formatted = DateUtils.setFullUTCHour(
              value,
              OrganizationHelpers.getWorkingHoursStart(
                state.myOrganization,
                value,
              ).getHours(),
            );
            setStartDate(formatted);
          }}
        />
        <DatePicker
          caption={t("Home.Book.Vacation.MoreThanDay") as string}
          initialValue={endDate}
          minDate={startDate}
          onChange={(value) => {
            const formatted = DateUtils.setFullUTCHour(
              value,
              OrganizationHelpers.getWorkingHoursEnd(
                state.myOrganization,
                value,
              ).getHours(),
            );
            setEndDate(formatted);
          }}
        />
        {!!unavailableSlots.length && (
          <Typography color="error" paddingTop={2} fontSize={14}>
            {t("Home.Book.Vacation.Disabled")}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={startDate > endDate || !!unavailableSlots.length}
          onClick={() => {
            createBooking({
              variables: {
                booking: {
                  start: startDate,
                  end: endDate,
                  comment: "",
                },
              },
            })
              .then(() => {
                dispatch(Actions.setLoading(true));
                dispatch(
                  Actions.notifySuccess(
                    t(["Home.ActiveBooking.Booking.title"]),
                  ),
                );
              })
              .catch((error) => {
                dispatch(
                  Actions.notifyError(
                    error?.message || t(["Errors.error_title"]),
                  ),
                );
              });
            dispatch(Actions.openModal());
          }}
        >
          {t("Routes.Home.Book.Confirm")}
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
}
