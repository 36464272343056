import { lazy, useState, useEffect, useReducer, useMemo } from "react";
import { Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setDefaultOptions as setDateFnsOptions } from "date-fns";
import { de, enUS } from "date-fns/locale";
import { ProtectedRoute, SharedStateRoute } from "shared/layouts";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { app } from "@microsoft/teams-js";
import { ThemeProvider, getThemeFromString } from "themes";
import CssBaseline from "@mui/material/CssBaseline";
import { initialState, reducer, Actions, SharedContext } from "shared/state";

const HomeTab = lazy(() => import("modules/home"));
const BookingsTab = lazy(() => import("modules/bookings"));
const FloorTab = lazy(() => import("modules/floor"));
const AccountTab = lazy(() => import("modules/account"));

export default function App() {
  const [appTheme, setAppTheme] = useState(getThemeFromString());
  const [state, dispatch] = useReducer(reducer, initialState);
  const sharedContext = useMemo(() => ({ state, dispatch, Actions }), [state]);
  const { t, i18n } = useTranslation();

  function setLang(context: any): void {
    const lang = (context.app.locale ?? "en-US").substring(0, 2);
    i18n.changeLanguage(lang);
    switch (lang) {
      case "en":
        setDateFnsOptions({ locale: enUS });
        break;
      case "de":
        setDateFnsOptions({ locale: de });
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    app
      .initialize()
      .then(() => {
        app.getContext().then((context: any) => {
          setAppTheme(getThemeFromString(context.app.theme));
          setLang(context);
        });

        app.registerOnThemeChangeHandler((theme: any) => {
          setAppTheme(getThemeFromString(theme));
        });

        app.notifySuccess();
      })
      .catch(() => {
        app.notifyFailure({
          reason: app.FailedReason.Timeout,
          message: "Loading error.",
        });
      });
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <SharedContext.Provider value={sharedContext}>
          <Routes>
            <Route path="/" element={<ProtectedRoute />}>
              <Route element={<SharedStateRoute />}>
                <Route index element={<HomeTab />} />
                <Route path="bookings" element={<BookingsTab />} />
                <Route path="floor-plan" element={<FloorTab />} />
                <Route path="account" element={<AccountTab />} />
              </Route>
            </Route>
            <Route path="*" element={<h1>{t(["Errors.page.notFound"])}</h1>} />
          </Routes>
        </SharedContext.Provider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
