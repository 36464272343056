import { useMemo, useReducer, useEffect } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TimeSlot, DateUtils } from "shared/utils/date";
import { filterActiveBookings } from "shared/utils/filter-booking";
import { OrganizationHelpers } from "shared/utils/organization";
import BookTelephoneBox from "assets/icons/BookTelephoneBox.svg";
import { AreaType } from "api/schema";
import { useMyActiveBookings } from "api/queries/bookings/myActiveBookings";
import { useUnavailableTimePeriods } from "api/queries/bookings/unavailableTimePeriods";
import { useCreateTelephoneBox } from "api/mutations/booking/createTelephoneBoxBooking";
import { useBuildingStructureForTelephoneBox } from "api/queries/building/buildingStructureForTelephoneBox";
import { useSharedData } from "shared/state";
import {
  initialState,
  reducer,
  Actions,
  BookTelephoneBoxContext,
} from "./state";
import {
  DialogHeader,
  TimePickers,
  DatePicker,
  DialogWrapper,
  ErrorMessage,
  PeriodPicker,
  SelectOptions,
} from "../components";
import {
  getBuildingOptions,
  getTelephoneBoxes,
  getFloorOptions,
} from "../../../utils/dropdown";

export default function BookTelephoneBoxModal() {
  const shared = useSharedData();
  const modalItem = shared?.state?.modalItem as AreaType | undefined;
  const [state, dispatch] = useReducer(reducer, initialState);
  const context = useMemo(() => ({ state, dispatch, Actions }), [state]);
  const hourlyBookingFlag =
    shared.state.myOrganization?.enabledFeatures.includes("hourly_booking");
  const { t } = useTranslation();

  const createBooking = useCreateTelephoneBox();

  const minmax = useMemo<TimeSlot>(() => {
    if (shared.state.myOrganization) {
      return [
        shared.state.myOrganization.workingHoursStart,
        shared.state.myOrganization.workingHoursEnd,
      ].map((value) =>
        OrganizationHelpers.parseOrganizationTime(value.toString()),
      ) as TimeSlot;
    }
    return [0, 0];
  }, [shared.state.myOrganization]);

  useEffect(() => {
    dispatch(Actions.setHoursRange(minmax));
  }, [minmax]);

  useEffect(() => {
    dispatch(Actions.selectDate(shared.state.modalInitialDate));
  }, [shared.state.modalInitialDate]);

  useEffect(() => {
    if (shared.state.selectedBuilding) {
      if (!state.selectedBuilding) {
        dispatch(Actions.selectBuilding(shared.state.selectedBuilding));
      } else if (typeof modalItem !== "undefined") {
        const building = shared.state.buildings.find(
          (b) => b.id === modalItem?.floor?.building?.id,
        );
        if (state.selectedBuilding.id !== building?.id) {
          dispatch(Actions.selectBuilding(building));
        }
      }
    }
  }, [shared.state.selectedBuilding, modalItem]);

  const { loading: loadingFloors } = useBuildingStructureForTelephoneBox(
    (data) => {
      dispatch(
        Actions.setFloors(
          data?.floors.filter(
            (e) =>
              e.floorType === "mixed" &&
              e.areas.some((el) => el.areaType === "telefon_box"),
          ),
        ),
      );
    },
    {
      building: state.selectedBuilding,
      date: state.selectedDate,
    },
  );

  useEffect(() => {
    if (state.floors) {
      if (typeof modalItem !== "undefined") {
        const floor = state.floors.find((f) => f.id === modalItem?.floor?.id);
        if (state.selectedFloor?.id !== floor?.id) {
          dispatch(Actions.selectFloor(floor));
        }
      }
    }
  }, [state.floors, modalItem]);

  useEffect(() => {
    if (state.selectedFloor) {
      dispatch(
        Actions.setTelephoneBoxes(
          (state.selectedFloor?.areas || []).filter(
            (e) => e.areaType === "telefon_box",
          ),
        ),
      );
    }
  }, [state.selectedFloor]);

  useEffect(() => {
    if (state.telephoneBoxes) {
      if (typeof modalItem !== "undefined") {
        const room = state.telephoneBoxes.find((r) => r.id === modalItem?.id);
        if (state.selectedTelephoneBox?.id !== room?.id) {
          dispatch(Actions.selectTelephoneBox(room));
        }
      }
    }
  }, [state.telephoneBoxes, modalItem]);

  const isFloorDisabled = !(state.selectedDate && state.selectedBuilding);
  const isTimePeriodDisabled = !state.selectedFloor;

  const { refetch: refetchTimePeriods } = useUnavailableTimePeriods(
    (data) => {
      const combinedData = [
        ...(shared.state.myBookedSlots ?? []),
        ...OrganizationHelpers.getUnavailableTimePeriods(data),
      ].sort((a, b) => a - b);

      const uniqueSlots = Array.from(new Set(combinedData));
      dispatch(Actions.setUnavailableSlots(uniqueSlots));
    },
    {
      date: state.selectedDate,
      areaId: state.selectedTelephoneBox?.id ?? "",
      skip: isTimePeriodDisabled || !Array.isArray(shared.state.myBookedSlots),
    },
  );

  const { refetch: refetchActiveBookings } = useMyActiveBookings(
    (data) => {
      const filteredByTelephoneBox = filterActiveBookings(data, "telefon_box");
      shared.dispatch(shared.Actions.setMyBookedSlots(filteredByTelephoneBox));
    },
    {
      start: state.selectedDate,
      end: state.selectedDate,
    },
  );

  useEffect(() => {
    refetchActiveBookings().then(() => {
      shared.dispatch(shared.Actions.setLoading(false));
    });
  }, [state.selectedDate]);

  useEffect(() => {
    refetchTimePeriods();
  }, [state.unavailableSlots]);

  return (
    <BookTelephoneBoxContext.Provider value={context}>
      <DialogWrapper open>
        <DialogHeader
          icon={BookTelephoneBox}
          title={t("Home.ActiveBooking.Booking.Popup.TelBox.Title")}
          subheader={t("Home.ActiveBooking.Booking.Popup.SubTitle")}
        />
        <DialogContent>
          <DatePicker
            initialValue={state.selectedDate}
            onChange={(value) => dispatch(Actions.selectDate(value))}
          />
          <SelectOptions
            placeholder={t("Home.Buildings.Select") as string}
            notAvailablePlaceholder={t("Home.Buildings.NotAvailable") as string}
            state={shared.state.buildings}
            value={state.selectedBuilding?.id ?? ""}
            setValue={(val) => dispatch(Actions.selectBuilding(val))}
            getOptions={() => getBuildingOptions(shared.state)}
          />
          <SelectOptions
            placeholder={t("Filter.Floors.Select") as string}
            notAvailablePlaceholder={t("Filter.Floors.NoFloors") as string}
            disabled={isFloorDisabled}
            state={state.floors}
            isLoading={loadingFloors}
            value={state?.selectedFloor?.id ?? ""}
            setValue={(val) => dispatch(Actions.selectFloor(val))}
            getOptions={() => getFloorOptions(state)}
          />
          <SelectOptions
            placeholder={t("Filter.TelBox.Select") as string}
            notAvailablePlaceholder={t("Filter.TelBox.NoBoxes") as string}
            disabled={!state.selectedFloor}
            state={state.telephoneBoxes}
            value={state?.selectedTelephoneBox?.id ?? ""}
            setValue={(val) => dispatch(Actions.selectTelephoneBox(val))}
            getOptions={() => getTelephoneBoxes(state)}
          />

          {Boolean(state.selectedTelephoneBox?.equipment.length) && (
            <Typography variant="subtitle2" display="block">
              Equipment:&nbsp;
              <Typography variant="caption">
                {(state.selectedTelephoneBox?.equipment || [])
                  .map((e) => e.name)
                  .join(", ")}
              </Typography>
            </Typography>
          )}

          <Typography variant="caption" display="block" gutterBottom>
            {t(["Time.Slots.selection"])}
          </Typography>
          <PeriodPicker
            bookingType="desk"
            unavailableSlots={state.unavailableSlots}
            selectedStart={state.hoursStart ?? 0}
            selectedEnd={state.hoursEnd ?? 0}
            onSelect={(start, end) => {
              dispatch(Actions.setHoursRange([start, end]));
            }}
          />
          {hourlyBookingFlag && (
            <TimePickers
              isDisabled={isTimePeriodDisabled}
              selectedDate={state.selectedDate}
              hoursStart={state.hoursStart}
              hoursEnd={state.hoursEnd}
              onChange={(start, end) => {
                dispatch(Actions.setHoursRange([start, end]));
              }}
              setHourRange={(timeSlot) =>
                dispatch(Actions.setHoursRange(timeSlot))
              }
              unavailableSlots={state.unavailableSlots}
              minmax={minmax}
            />
          )}
          {state.hoursStart === state.hoursEnd && <ErrorMessage />}
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            disabled={
              !state.selectedTelephoneBox?.id ||
              state.hoursStart === state.hoursEnd
            }
            onClick={() => {
              createBooking({
                variables: {
                  areaId: state.selectedTelephoneBox?.id ?? "",
                  start: DateUtils.setFullUTCHour(
                    state.selectedDate,
                    state.hoursStart ?? 0,
                  ),
                  end: DateUtils.setFullUTCHour(
                    state.selectedDate,
                    state.hoursEnd ?? 0,
                  ),
                },
              })
                .then(() => {
                  shared.dispatch(shared.Actions.setLoading(true));
                  shared.dispatch(
                    shared.Actions.notifySuccess(
                      t(["Home.ActiveBooking.Booking.title"]),
                    ),
                  );
                })
                .catch((error) => {
                  shared.dispatch(
                    shared.Actions.notifyError(
                      error?.message || t(["Errors.error_title"]),
                    ),
                  );
                });
              shared.dispatch(shared.Actions.openModal());
            }}
          >
            {t(["Routes.Home.Book.Confirm"])}
          </Button>
        </DialogActions>
      </DialogWrapper>
    </BookTelephoneBoxContext.Provider>
  );
}
