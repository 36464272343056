import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { AreaType, AreaParkings, ParkingType } from "api/schema";
import { areasParkings } from "gql/queries/areas/areasParkings";

interface GqlData {
  areasParkings: AreaParkings[];
}

interface GqlVars {
  areaIds: string[];
  date: string;
}

type OnSettled = (data: ParkingType[]) => void;

interface QueryParams {
  date: Date;
  areas?: AreaType[];
}

export function useAreaParkings(onSettled?: OnSettled, params?: QueryParams) {
  const areaIds = (params?.areas || [])
    .filter((a) => a?.areaType === "parking")
    .map((a) => a?.id);

  const q = useQuery<GqlData, GqlVars>(areasParkings, {
    variables: {
      areaIds,
      date: params?.date.toISOString() ?? "",
    },
    skip: !(areaIds.length > 0),
  });

  useEffect(() => {
    if (q.data?.areasParkings) {
      const flatData = [...q.data.areasParkings].flatMap(
        (area) =>
          area.parkings.map((parking) => ({
            ...parking,
            areaId: area.areaId,
          })) || [],
      );
      onSettled?.(flatData);
    }
  }, [q.data]);

  return q;
}
