import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { ColleagueType } from "api/schema";
import { getColleague } from "gql/queries/colleagues/colleague";

interface GqlData {
  colleagues: ColleagueType[];
}

interface GqlVars {
  userID: string;
}

type OnSettled = (data: ColleagueType) => void;

interface QueryParams {
  skip?: boolean;
  userID: string;
}

export function useGetColleague(onSettled?: OnSettled, params?: QueryParams) {
  const q = useQuery<GqlData, GqlVars>(getColleague, {
    variables: {
      userID: params?.userID.toString() ?? "",
    },
    skip: Boolean(params?.skip),
  });

  useEffect(() => {
    if (q.data?.colleagues) {
      onSettled?.(q.data.colleagues[0]);
    }
  }, [q.data]);

  return q;
}
