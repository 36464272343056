import { gql } from "@apollo/client";

export const getBuildingStructureForFreeSeats = gql`
  query getBuildingStructureForFreeSeats(
    $buildingId: ID!
    $date: Date!
    $areaType: AreaTypeEnum
  ) {
    building(buildingId: $buildingId) {
      id
      name
      image
      address
      numberOfFreeSpacesRooms
      floors {
        id
        floorLabel
        floorNumber
        floorType
        areas(areaType: $areaType) {
          id
          areaType
          image
          name
          isBookable
          numberOfAvailableDesksHourlyByAreaType(date: $date) {
            areaType
            desksAvailable
            freeSlots
            totalSlots
          }
          numberOfDesksByAreaType {
            areaType
            numberOfDesks
          }
        }
        numberOfTotalFreeSpaces
        numberOfAvailableFreeSpaces(date: $date)
        numberOfAvailableDesksHourlyByAreaType(date: $date) {
          areaType
          desksAvailable
          freeSlots
          totalSlots
        }
      }
    }
  }
`;
