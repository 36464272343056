import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { UserProblemSubjectType } from "api/schema";
import { getSubjects } from "gql/queries/userProblemSubjects";

interface GqlData {
  userProblemSubjects: UserProblemSubjectType[];
}

interface GqlVars {}

type OnSettled = (data: UserProblemSubjectType[]) => void;

interface QueryParams {
  skip?: boolean;
}

export function useProblemSubjects(
  onSettled?: OnSettled,
  params?: QueryParams,
) {
  const q = useQuery<GqlData, GqlVars>(getSubjects, {
    skip: Boolean(params?.skip),
  });

  useEffect(() => {
    if (q.data?.userProblemSubjects) {
      onSettled?.(q.data.userProblemSubjects);
    }
  }, [q.data]);

  return q;
}
