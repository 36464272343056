import { gql } from "@apollo/client";

export const getMe = gql`
  query getMe {
    me {
      id
      name
      email
      permissions
      userprofile {
        avatar
        phoneNumber
        defaultBuilding {
          id
          name
        }
        department {
          id
          name
        }
        favouriteDesks
        favouriteSeats: favouriteAreas(roomType: free_spaces_room)
        favouriteMeetingRooms: favouriteAreas(roomType: meeting_room)
        favouriteColleagues
        visibleForColleagues
        visibleCalendar
        visibleCalendarInPrivateMode
        visibleHomeoffice
        homeofficeInfo
        language
        organization {
          id
          name
        }
        licensePlate
        enablePushNotifications
      }
      todayHappinessVotes
      hasGoogleAccount
      hasMicrosoftAccount
      organizationChoices {
        id
        name
      }
      isMultiBooker
    }
  }
`;
