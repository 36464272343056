import { PropsWithChildren } from "react";
import { Typography, Divider, LinearProgress, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Media } from "shared/utils/media";

const ControlsContainer = styled("div")({
  padding: "8px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [Media.Min900]: {
    alignItems: "start",
    justifyContent: "space-between",
  },
});

const ControlsWrapper = styled("div")({
  display: "flex",
  gap: 10,
});

interface PageHeaderProps extends PropsWithChildren {
  title: string;
  subtitle?: string;
  loading?: boolean;
}

export function PageHeader({
  title,
  subtitle = "",
  loading = false,
  children,
}: PageHeaderProps) {
  return (
    <header>
      <ControlsContainer>
        <Box>
          <Typography
            variant="body1"
            fontSize={14}
            fontWeight={700}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            {title}
          </Typography>
          <Typography variant="body2" fontSize={14}>
            {subtitle}
          </Typography>
        </Box>

        <ControlsWrapper>{children}</ControlsWrapper>
      </ControlsContainer>
      <Divider />
      <LinearProgress sx={{ visibility: loading ? "visible" : "hidden" }} />
    </header>
  );
}
