import { OrganizationType, BuildingType, UserAttendanceType } from "api/schema";
import { MyInfo } from "api/queries/getMe";
import { AlertColor } from "@mui/material";

export enum ModalType {
  BOOKING,
  BOOK_DESK,
  BOOK_MEETING_ROOM,
  BOOK_PARKING_SPOT,
  BOOK_FREE_SPACE,
  BOOK_TELEPHONE_BOX,
  VACATION,
  BUSINESS_TRIP,
  WORK_FROM_HOME,
  REPORT_PROBLEM,
  FAVOURITES,
  SICK_DAY,
  CANCEL_BOOKING,
  BOOKING_INFO,
  TIME_TRACKING,
  CLOCK_OUT,
}

export interface NotificationProps {
  isOpen?: boolean;
  color?: AlertColor;
  body?: string;
}

export interface State {
  isLoading: boolean;
  user?: MyInfo;
  hasFavourites?: boolean;
  myOrganization?: OrganizationType;
  startOfWeek: Date;
  buildings: BuildingType[];
  selectedBuilding?: BuildingType;
  modalOpen?: ModalType;
  modalInitialDate: Date;
  modalItem?: unknown;
  myBookedSlots?: number[];
  notification?: NotificationProps;
  userAttendances: UserAttendanceType[];
}

export const initialState: State = {
  isLoading: false,
  startOfWeek: new Date(),
  buildings: [],
  modalInitialDate: new Date(),
  userAttendances: [],
};

export enum ActionType {
  RESET,
  SET_LOADING,
  SET_USER,
  SET_ORGANIZATION,
  PREV_WEEK,
  NEXT_WEEK,
  SET_BUILDINGS,
  SELECT_BUILDING,
  SELECT_BUILDING_BY_ID,
  SET_ATTENDANCES,
  OPEN_MODAL,
  SET_MODAL_INITIAL_DATE,
  SET_MODAL_ITEM,
  SET_MY_BOOKED_SLOTS,
  NOTIFY_CLOSE,
  NOTIFY_ERROR,
  NOTIFY_SUCCESS,
}

export interface Action {
  type: ActionType;
  payload?: unknown;
}
