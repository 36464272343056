import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { BuildingType } from "api/schema";
import { getBuildingStructureForFreeSeats } from "gql/queries/building/buildingStructureForFreeSeats";
import { DateUtils } from "shared/utils/date";

interface GqlData {
  building: BuildingType;
}

interface GqlVars {
  buildingId: string;
  date: string;
}

type OnSettled = (data: BuildingType) => void;

interface QueryParams {
  building?: BuildingType;
  date?: Date | string;
}

export function useBuildingStructureForFreeSpace(
  onSettled?: OnSettled,
  params?: QueryParams,
) {
  const q = useQuery<GqlData, GqlVars>(getBuildingStructureForFreeSeats, {
    variables: {
      buildingId: params?.building?.id ?? "",
      date: DateUtils.formatDate(params?.date ?? new Date()),
    },
    skip: !params?.building?.id,
  });

  useEffect(() => {
    if (q.data?.building) {
      onSettled?.(q.data.building);
    }
  }, [q.data]);

  return q;
}
