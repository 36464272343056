import { useMutation } from "@apollo/client";
import { FavouriteTypeEnum } from "api/schema";
import { addFavourite } from "gql/mutations/favourites/addFavourite";

interface GqlData {
  addFavourite: boolean;
}

interface GqlVars {
  favouriteId: string;
  favouriteType: FavouriteTypeEnum;
}

export function useAddFavourite() {
  return useMutation<GqlData, GqlVars>(addFavourite)[0];
}
