import { DateUtils, TimeSlot } from "shared/utils/date";
import { Action, ActionType } from "./definitions";

export namespace Actions {
  export function reset(): Action {
    return {
      type: ActionType.RESET,
    };
  }

  export function selectDate(date?: Date | null): Action {
    return {
      type: ActionType.SELECT_DATE,
      payload: DateUtils.toFullHour(date ?? new Date()),
    };
  }

  export function setUnavailableSlots(arr?: number[]): Action {
    return {
      type: ActionType.SET_UNAVAILABLE_SLOTS,
      payload: Array.isArray(arr) ? arr : [],
    };
  }

  export function setHoursRange(value?: TimeSlot): Action {
    return {
      type: ActionType.SET_HOURS_RANGE,
      payload: value ?? [0, 0],
    };
  }
}
