import { gql } from "@apollo/client";

export const bookMeetingRoom = gql`
  mutation bookMeetingRoom($areaId: ID!, $start: DateTime!, $end: DateTime!) {
    createBooking(booking: { areaId: $areaId, start: $start, end: $end }) {
      id
      start
      end
      area {
        areaType
        name
        floor {
          floorLabel
          floorNumber
          floorType
          building {
            name
            address
          }
        }
      }
    }
  }
`;
