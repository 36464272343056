import { gql } from "@apollo/client";

export const createBooking = gql`
  mutation createBooking($booking: CreateSickDayBookingInput!) {
    createSickDayBooking(booking: $booking) {
      sickDay
      start
      end
      comment
    }
  }
`;
