import { gql } from "@apollo/client";

export const createBooking = gql`
  mutation createBooking($booking: CreateHomeOfficeBookingTypeInput!) {
    createHomeOfficeBooking(booking: $booking) {
      homeOffice
      start
      end
      comment
    }
  }
`;
