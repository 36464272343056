import { gql } from "@apollo/client";

export const getSubjects = gql`
  query getSubjects {
    userProblemSubjects {
      id
      en
      de
    }
  }
`;
