import { useTranslation } from "react-i18next";
import { Box, Typography, CircularProgress } from "@mui/material";

export default function LoadingComponent() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CircularProgress size={20} />
      <Typography variant="caption" display="block" gutterBottom>
        {t(["Bookings.Loading"])}
      </Typography>
    </Box>
  );
}
