import { ComponentsProps } from "../models";

export const alert: ComponentsProps = {
  MuiSnackbar: {
    defaultProps: {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
    },
    styleOverrides: {
      anchorOriginBottomLeft: {
        bottom: "8px !important",
        left: "20px !important",
        right: "20px !important",
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      standard: {
        border: "1px solid currentcolor",
      },
      standardSuccess: (props) => ({
        backgroundColor: props.theme.palette.success.light,
        color: props.theme.palette.success.main,
      }),
      standardError: (props) => ({
        backgroundColor: props.theme.palette.error.light,
        color: props.theme.palette.error.main,
      }),
    },
  },
};
