import { ComponentsProps } from "../models";

export const textField: ComponentsProps = {
  MuiInputBase: {
    defaultProps: {
      size: "small",
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: (props) => ({
        backgroundColor: props.theme.palette.background.paper,
      }),
      inputSizeSmall: {
        padding: "5px 14px",
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      size: "small",
      variant: "outlined",
    },
  },
};
