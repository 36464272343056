import { OrganizationType, BuildingType, UserAttendanceType } from "api/schema";
import { MyInfo } from "api/queries/getMe";
import { OrganizationHelpers } from "shared/utils/organization";
import { addDays, subDays } from "date-fns";
import {
  State,
  Action,
  ActionType,
  initialState,
  ModalType,
} from "./definitions";

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.RESET: {
      return {
        ...initialState,
      };
    }
    case ActionType.SET_LOADING: {
      return {
        ...state,
        isLoading: action.payload as boolean,
      };
    }
    case ActionType.SET_USER: {
      const user = action.payload as MyInfo;
      return {
        ...state,
        user,
        hasFavourites:
          (user?.userprofile?.favouriteDesks?.length ?? 0) > 0 ||
          (user?.userprofile?.favouriteMeetingRooms?.length ?? 0) > 0,
      };
    }
    case ActionType.SET_ORGANIZATION: {
      const org = action.payload as OrganizationType;
      return {
        ...state,
        myOrganization: org,
        startOfWeek: OrganizationHelpers.orgStartOfWeek(org),
      };
    }
    case ActionType.PREV_WEEK: {
      return {
        ...state,
        startOfWeek: subDays(state.startOfWeek, 7),
      };
    }
    case ActionType.NEXT_WEEK: {
      return {
        ...state,
        startOfWeek: addDays(state.startOfWeek, 7),
      };
    }
    case ActionType.SET_BUILDINGS: {
      const buildings = (action.payload as BuildingType[]) ?? [];
      return {
        ...state,
        buildings,
        selectedBuilding: buildings[0],
      };
    }
    case ActionType.SELECT_BUILDING: {
      return {
        ...state,
        selectedBuilding: action.payload as BuildingType,
      };
    }
    case ActionType.SELECT_BUILDING_BY_ID: {
      const building = state.buildings.find((i) => i.id === action.payload);
      return {
        ...state,
        selectedBuilding: building,
      };
    }
    case ActionType.SET_ATTENDANCES: {
      return {
        ...state,
        userAttendances: action.payload as UserAttendanceType[],
      };
    }
    case ActionType.OPEN_MODAL: {
      const payload = action.payload as ModalType | undefined;
      const newState = {
        ...state,
        ...({ modalOpen: payload } as Partial<State>),
      };
      if (typeof payload === "undefined") {
        Object.assign(newState, {
          modalInitialDate: new Date(),
        } as Partial<State>);
        if (state.modalOpen !== ModalType.FAVOURITES) {
          Object.assign(newState, {
            modalItem: undefined,
            myBookedSlots: undefined,
          } as Partial<State>);
        }
      }
      return newState;
    }
    case ActionType.SET_MODAL_INITIAL_DATE: {
      return {
        ...state,
        modalInitialDate: action.payload as Date,
      };
    }
    case ActionType.SET_MODAL_ITEM: {
      return {
        ...state,
        modalItem: action.payload,
      };
    }
    case ActionType.SET_MY_BOOKED_SLOTS: {
      return {
        ...state,
        myBookedSlots: action.payload as number[],
      };
    }
    case ActionType.NOTIFY_CLOSE: {
      return {
        ...state,
        notification: {
          ...state.notification,
          isOpen: false,
        },
      };
    }
    case ActionType.NOTIFY_ERROR: {
      return {
        ...state,
        notification: {
          isOpen: true,
          color: "error",
          body: action.payload as string,
        },
      };
    }
    case ActionType.NOTIFY_SUCCESS: {
      return {
        ...state,
        notification: {
          isOpen: true,
          color: "success",
          body: action.payload as string,
        },
      };
    }
    default:
      throw new Error("Unknown action");
  }
}
