import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { DeskType, AreaType } from "api/schema";
import { getDesks } from "gql/queries/desksByIds";
import { formatISO } from "date-fns";

interface FavDesksCombined extends AreaType, DeskType {}

interface GqlData {
  desksByIds: FavDesksCombined[];
}

interface GqlVars {
  desksIds: string[];
  date: string;
}

type OnSettled = (data: FavDesksCombined[]) => void;

interface QueryParams {
  date?: Date;
  ids: string[];
  skip?: boolean;
}

export function useDesksById(onSettled?: OnSettled, params?: QueryParams) {
  const today = new Date();
  const day = params?.date ? params?.date : today;

  const q = useQuery<GqlData, GqlVars>(getDesks, {
    variables: {
      desksIds: params?.ids ?? [],
      date: formatISO(day),
    },
    skip: Boolean(params?.skip),
  });

  useEffect(() => {
    if (q.data?.desksByIds) {
      onSettled?.(q.data?.desksByIds);
    }
  }, [q.data]);

  return q;
}
