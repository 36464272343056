import {
  DialogTitle,
  IconButton,
  CardHeader as MuiCardHeader,
  Avatar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useSharedData } from "shared/state";

const CardHeader = styled(MuiCardHeader)({
  padding: 0,
});

interface DialogHeaderProps {
  icon: any;
  title: string;
  subheader: string;
}

export default function DialogHeader({
  icon,
  title,
  subheader,
}: DialogHeaderProps) {
  const { dispatch, Actions } = useSharedData();

  return (
    <DialogTitle>
      <CardHeader
        avatar={<Avatar variant="rounded" src={icon} />}
        title={<Typography variant="subtitle2">{title}</Typography>}
        subheader={subheader}
      />
      <IconButton
        onClick={() => dispatch(Actions.openModal())}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
}
