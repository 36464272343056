import { OrganizationType, BuildingType, UserAttendanceType } from "api/schema";
import { MyInfo } from "api/queries/getMe";
import { Action, ActionType, ModalType } from "./definitions";

export namespace Actions {
  export function reset(): Action {
    return {
      type: ActionType.RESET,
    };
  }

  export function setLoading(flag?: boolean): Action {
    return {
      type: ActionType.SET_LOADING,
      payload: Boolean(flag),
    };
  }

  export function setUser(user?: MyInfo): Action {
    return {
      type: ActionType.SET_USER,
      payload: user,
    };
  }

  export function setOrganization(value?: OrganizationType): Action {
    return {
      type: ActionType.SET_ORGANIZATION,
      payload: value,
    };
  }

  export function nextWeek(): Action {
    return {
      type: ActionType.NEXT_WEEK,
    };
  }

  export function prevWeek(): Action {
    return {
      type: ActionType.PREV_WEEK,
    };
  }

  export function setBuildings(arr?: BuildingType[]): Action {
    return {
      type: ActionType.SET_BUILDINGS,
      payload: Array.isArray(arr) ? arr : [],
    };
  }

  export function selectBuilding(value?: BuildingType): Action {
    return {
      type: ActionType.SELECT_BUILDING,
      payload: value,
    };
  }

  export function selectBuildingById(value?: string): Action {
    return {
      type: ActionType.SELECT_BUILDING_BY_ID,
      payload: value,
    };
  }

  export function setAttendances(value?: UserAttendanceType[]): Action {
    return {
      type: ActionType.SET_ATTENDANCES,
      payload: value,
    };
  }

  export function openModal(value?: ModalType): Action {
    return {
      type: ActionType.OPEN_MODAL,
      payload: value,
    };
  }

  export function setModalInitialDate(value?: Date | null): Action {
    return {
      type: ActionType.SET_MODAL_INITIAL_DATE,
      payload: value ?? new Date(),
    };
  }

  export function setModalItem(value?: unknown): Action {
    return {
      type: ActionType.SET_MODAL_ITEM,
      payload: value,
    };
  }

  export function setMyBookedSlots(arr?: number[]): Action {
    return {
      type: ActionType.SET_MY_BOOKED_SLOTS,
      payload: Array.isArray(arr) ? arr : [],
    };
  }

  export function notifyClose(): Action {
    return {
      type: ActionType.NOTIFY_CLOSE,
    };
  }

  export function notifyError(value: string): Action {
    return {
      type: ActionType.NOTIFY_ERROR,
      payload: value,
    };
  }

  export function notifySuccess(value: string): Action {
    return {
      type: ActionType.NOTIFY_SUCCESS,
      payload: value,
    };
  }
}
