import { gql } from "@apollo/client";

export const getBuildingStructureForParkingSpots = gql`
  query getBuildingStructureForParkingSpots(
    $buildingId: ID!
    $date: Date!
    $areaType: AreaTypeEnum
  ) {
    building(buildingId: $buildingId) {
      id
      name
      image
      address
      floors {
        id
        floorLabel
        floorNumber
        floorType
        areas(areaType: $areaType) {
          id
          areaType
          image
          name
          isBookable
        }
      }
      numberOfAvailableParkings(date: $date)
      numberOfTotalParkings
    }
  }
`;
