import { useMutation } from "@apollo/client";
import { BookingType } from "api/schema";
import { cancelBookings } from "gql/mutations/booking/cancelBookings";

interface GqlData {
  cancelBookings: BookingType[];
}

interface GqlVars {
  bookingIds: string[];
}

export function useCancelBookings() {
  return useMutation<GqlData, GqlVars>(cancelBookings)[0];
}
