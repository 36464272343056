import { gql } from "@apollo/client";

export const createBooking = gql`
  mutation createBooking($booking: CreateTravelDayBookingTypeInput!) {
    createTravelDayBooking(booking: $booking) {
      travelDay
      start
      end
      comment
    }
  }
`;
