import { AreaType, FloorType, BuildingType, DeskType } from "api/schema";
import { TimeSlot } from "shared/utils/date";
import { State, initialState, Action, ActionType } from "./definitions";

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.RESET: {
      return {
        ...initialState,
      };
    }
    case ActionType.SELECT_DATE: {
      return {
        ...state,
        selectedDate: action.payload as Date,
      };
    }
    case ActionType.SELECT_BUILDING: {
      return {
        ...state,
        selectedBuilding: action.payload as BuildingType,
        floors: [],
        selectedFloor: undefined,
        areas: [],
        selectedArea: undefined,
        desks: [],
        selectedDesk: undefined,
      };
    }
    case ActionType.SET_FLOORS: {
      const floors = action.payload as FloorType[];
      const selectedFloor = floors.find(
        (f) => f.id === state.selectedFloor?.id,
      );
      return {
        ...state,
        floors,
        selectedFloor,
        areas: [],
        selectedArea: undefined,
        desks: [],
        selectedDesk: undefined,
      };
    }
    case ActionType.SELECT_FLOOR: {
      return {
        ...state,
        selectedFloor: action.payload as FloorType,
        areas: [],
        selectedArea: undefined,
        desks: [],
        selectedDesk: undefined,
      };
    }
    case ActionType.SET_AREAS: {
      const areas = action.payload as AreaType[];
      const selectedArea = areas.find((a) => a.id === state.selectedArea?.id);
      return {
        ...state,
        areas,
        selectedArea,
        desks: [],
        selectedDesk: undefined,
      };
    }
    case ActionType.SELECT_AREA: {
      return {
        ...state,
        selectedArea: action.payload as AreaType,
        desks: [],
        selectedDesk: undefined,
      };
    }
    case ActionType.SET_DESKS: {
      const desks = action.payload as DeskType[];
      const selectedDesk = desks.find((d) => d.id === state.selectedDesk?.id);
      return {
        ...state,
        desks,
        selectedDesk,
      };
    }
    case ActionType.SELECT_DESK: {
      return {
        ...state,
        selectedDesk: action.payload as DeskType,
      };
    }
    case ActionType.SET_UNAVAILABLE_SLOTS: {
      return {
        ...state,
        unavailableSlots: action.payload as number[],
      };
    }
    case ActionType.SET_IS_FAVOURITE_DESK: {
      return {
        ...state,
        isFavDesk: action.payload as boolean,
      };
    }
    case ActionType.SET_HOURS_RANGE: {
      const [start, end] = action.payload as TimeSlot;
      return {
        ...state,
        hoursStart: start,
        hoursEnd: end,
      };
    }
    default:
      throw new Error("Unknown action");
  }
}
