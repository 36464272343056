import { useMutation } from "@apollo/client";
import { startUserAttendance } from "gql/mutations/attendance/startUserAttendance";
import { UserAttendanceType } from "api/schema";

interface GqlData {
  startUserAttendance: UserAttendanceType;
}

interface GqlVars {
  startTime: string;
}

export function useStartUserAttendance() {
  return useMutation<GqlData, GqlVars>(startUserAttendance)[0];
}
