import { gql } from "@apollo/client";

export const getBuildings = gql`
  query getBuildings {
    buildingsInfo {
      id
      name
      image
      address
    }
  }
`;
