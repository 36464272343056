import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { AreaType } from "api/schema";
import { getAreas } from "gql/queries/areas/areasByIds";
import { DateUtils } from "shared/utils/date";

interface GqlData {
  areasByIds: AreaType[];
}

interface GqlVars {
  areasIds: string[];
  date: string;
}

type OnSettled = (data: AreaType[]) => void;

interface QueryParams {
  date?: Date;
  areasIds: string[];
}

export function useAreasById(onSettled?: OnSettled, params?: QueryParams) {
  const day = params?.date ? params?.date : new Date();

  const q = useQuery<GqlData, GqlVars>(getAreas, {
    variables: {
      areasIds: params?.areasIds ?? [],
      date: DateUtils.formatDate(day),
    },
    skip: !params?.areasIds.length,
  });

  useEffect(() => {
    if (q.data?.areasByIds) {
      onSettled?.(q.data?.areasByIds);
    }
  }, [q.data]);

  return q;
}
