import { Theme } from "@mui/material/styles";
import { theme as muiLightTheme } from "./mui5/default";
import { theme as muiDarkTheme } from "./mui5/dark";
import { theme as muiContrastTheme } from "./mui5/contrast";

export type TeamsTheme = "default" | "dark" | "contrast";

export function getThemeFromString(name?: TeamsTheme): Theme {
  let resolvedTheme = muiLightTheme;
  switch (name) {
    case "contrast":
      resolvedTheme = muiContrastTheme;
      break;
    case "dark":
      resolvedTheme = muiDarkTheme;
      break;
    default:
      break;
  }
  return resolvedTheme;
}

export { ThemeProvider } from "@mui/material";
