import { gql } from "@apollo/client";

export const removeFavourite = gql`
  mutation removeFavourite(
    $favouriteId: ID!
    $favouriteType: FavouriteTypeEnum!
  ) {
    removeFavourite(favouriteId: $favouriteId, favouriteType: $favouriteType)
  }
`;
