import { Card as MuiCard, CardHeader, Avatar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

interface ActionCardProps {
  image: any;
  title: string;
  onSelect: () => void;
}

const Card = styled(MuiCard)((props) => ({
  padding: 8,
  minHeight: 100,
  border: "1px solid",
  borderColor: props.theme.palette.action.selected,
  " .MuiCardHeader-root": {
    padding: 0,
  },
  " .MuiTypography-root": {
    marginTop: 8,
  },
  "&:hover": {
    cursor: "pointer",
    backgroundColor: props.theme.palette.action.hover,
  },
}));

export default function ActionCard({
  image,
  title,
  onSelect,
}: ActionCardProps) {
  return (
    <Card elevation={0} onClick={onSelect}>
      <CardHeader avatar={<Avatar variant="rounded" src={image} />} />
      <Typography variant="body2">{title}</Typography>
    </Card>
  );
}
