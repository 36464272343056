import { gql } from "@apollo/client";

export const createBooking = gql`
  mutation createBooking($booking: CreateParkingBookingTypeInput!) {
    createParkingBooking(booking: $booking) {
      start
      end
      area {
        areaType
        floor {
          floorLabel
          floorNumber
          floorType
          building {
            name
          }
        }
        name
      }
      parking {
        type: combinedType
        label
        numberOnFloor
      }
      comment
    }
  }
`;
