import { useMutation } from "@apollo/client";
import { BookingType, CreateHomeOfficeBookingTypeInput } from "api/schema";
import { createBooking } from "gql/mutations/booking/createHomeOfficeBooking";

interface GqlData {
  createHomeOfficeBooking: BookingType;
}

interface GqlVars {
  booking: CreateHomeOfficeBookingTypeInput;
}

export function useCreateHomeOfficeBooking() {
  return useMutation<GqlData, GqlVars>(createBooking)[0];
}
