import { PropsWithChildren } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const CenteredContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexGrow: 1,
  flexDirection: "column",
  gap: 20,
});

const Text = styled(Typography)({
  margin: "10px 0",
});

interface PageContentEmptyProps extends PropsWithChildren {
  image: string;
  title: string;
  subtitle: string;
}

export function PageContentEmpty({
  image,
  title,
  subtitle,
  children,
}: PageContentEmptyProps) {
  return (
    <CenteredContainer>
      {image && <img src={image} alt="" />}
      <div>
        <Text variant="h6" align="center">
          {title}
        </Text>
        <Text variant="body1" fontSize={14} fontWeight={500} align="center">
          {subtitle}
        </Text>
      </div>
      <div>{children}</div>
    </CenteredContainer>
  );
}
