import { ComponentsProps } from "../models";

export const dialog: ComponentsProps = {
  MuiDialog: {
    defaultProps: {},
    styleOverrides: {
      paper: (props) => ({
        border: `1px solid ${props.theme.palette.common.white}`,
      }),
    },
  },
};
