import { useMutation } from "@apollo/client";
import { BookingType, CreateVacationBookingTypeInput } from "api/schema";
import { createBooking } from "gql/mutations/booking/createVacationBooking";

interface GqlData {
  createVacationBooking: BookingType[];
}

interface GqlVars {
  booking: CreateVacationBookingTypeInput;
}

export function useCreateVacationBooking() {
  return useMutation<GqlData, GqlVars>(createBooking)[0];
}
