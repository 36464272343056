import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { OrganizationType, LanguageEnumType } from "api/schema";
import { getMyOrganization } from "gql/queries/myOrganization";
import i18n from "i18n";

interface GqlData {
  myOrganization: OrganizationType;
}

interface GqlVars {
  language: LanguageEnumType;
}

type OnSettled = (data: OrganizationType) => void;

interface QueryParams {
  skip?: boolean;
}

export function useMyOrganization(onSettled?: OnSettled, params?: QueryParams) {
  const q = useQuery<GqlData, GqlVars>(getMyOrganization, {
    variables: { language: i18n.language as LanguageEnumType },
    skip: Boolean(params?.skip),
  });

  useEffect(() => {
    if (q.data?.myOrganization) {
      onSettled?.(q.data.myOrganization);
    }
  }, [q.data]);

  return q;
}
