import { useMutation } from "@apollo/client";
import { BookingType } from "api/schema";
import { bookFreeSpace } from "gql/mutations/booking/createFreeSpaceBooking";

interface GqlData {
  createBooking: BookingType;
}

interface GqlVars {
  areaId: string;
  start: Date;
  end: Date;
}

export function useCreateFreeSpace() {
  return useMutation<GqlData, GqlVars>(bookFreeSpace)[0];
}
