import { useMutation } from "@apollo/client";
import { AddUserProblemInput, UserProblemType } from "api/schema";
import { reportProblem } from "gql/mutations/reportProblem";

interface GqlData {
  addUserProblem: UserProblemType;
}

interface GqlVars {
  problem: AddUserProblemInput;
}

export function useReportAProblem() {
  return useMutation<GqlData, GqlVars>(reportProblem)[0];
}
