import { BookingType } from "api/schema";

interface MyActiveBookings {
  actives: BookingType[];
  checkedIn: BookingType[];
}

type BookingTypeEnum =
  | "desk"
  | "meeting_room"
  | "free_spaces_room"
  | "parking"
  | "telefon_box"
  | "vacation"
  | "travel-day"
  | "sick-day"
  | "home-office";

function getDeskFilter(booking: BookingType): boolean {
  return Boolean(
    booking.desk ||
      booking.area?.areaType === "free_spaces_room" ||
      booking.homeOffice ||
      booking.sickDay ||
      booking.travelDay ||
      booking.vacation,
  );
}

function getMeetingRoomFilter(booking: BookingType): boolean {
  return Boolean(
    booking.area?.areaType === "meeting_room" ||
      booking.area?.areaType === "telefon_box" ||
      booking.homeOffice ||
      booking.sickDay ||
      booking.travelDay ||
      booking.vacation,
  );
}

function getFreeSpaceRoomFilter(booking: BookingType): boolean {
  return Boolean(
    booking.desk ||
      booking.area?.areaType === "free_spaces_room" ||
      booking.area?.areaType === "telefon_box" ||
      booking.homeOffice ||
      booking.sickDay ||
      booking.travelDay ||
      booking.vacation,
  );
}

function getTelephoneBoxFilter(booking: BookingType): boolean {
  return Boolean(
    booking.area?.areaType === "meeting_room" ||
      booking.area?.areaType === "telefon_box" ||
      booking.homeOffice ||
      booking.sickDay ||
      booking.travelDay ||
      booking.vacation,
  );
}

function getParkingFilter(booking: BookingType): boolean {
  return Boolean(
    booking.parking ||
      booking.homeOffice ||
      booking.sickDay ||
      booking.vacation,
  );
}

function getTravelFilter(booking: BookingType): boolean {
  return Boolean(
    booking.desk ||
      booking.area?.areaType === "free_spaces_room" ||
      booking.area?.areaType === "meeting_room" ||
      booking.area?.areaType === "telefon_box" ||
      booking.homeOffice ||
      booking.sickDay ||
      booking.travelDay ||
      booking.vacation,
  );
}

export function filterActiveBookings(
  bookings: MyActiveBookings,
  type: BookingTypeEnum,
): number[] {
  const myBookingsSlots: number[] = [];
  let filtered: BookingType[] = [];

  switch (type) {
    case "desk":
      filtered = [
        ...bookings.actives.filter(getDeskFilter),
        ...bookings.checkedIn.filter(getDeskFilter),
      ];
      break;
    case "meeting_room":
      filtered = [
        ...bookings.actives.filter(getMeetingRoomFilter),
        ...bookings.checkedIn.filter(getMeetingRoomFilter),
      ];
      break;
    case "free_spaces_room":
      filtered = [
        ...bookings.actives.filter(getFreeSpaceRoomFilter),
        ...bookings.checkedIn.filter(getFreeSpaceRoomFilter),
      ];
      break;
    case "telefon_box":
      filtered = [
        ...bookings.actives.filter(getTelephoneBoxFilter),
        ...bookings.checkedIn.filter(getTelephoneBoxFilter),
      ];
      break;
    case "parking":
      filtered = [
        ...bookings.actives.filter(getParkingFilter),
        ...bookings.checkedIn.filter(getParkingFilter),
      ];
      break;
    case "travel-day":
      filtered = [
        ...bookings.actives.filter(getTravelFilter),
        ...bookings.checkedIn.filter(getTravelFilter),
      ];
      break;
    case "vacation":
    case "sick-day":
    case "home-office":
      filtered = [...bookings.actives, ...bookings.checkedIn];
      break;
    default:
      break;
  }

  filtered.forEach((range) => {
    const start = new Date(range.start);
    const end = new Date(range.end);
    for (let i = start.getUTCHours(); i < end.getUTCHours(); i++) {
      myBookingsSlots.push(i);
    }
  });
  return myBookingsSlots;
}
