import { useMutation } from "@apollo/client";
import { BookingType, CreateTravelDayBookingTypeInput } from "api/schema";
import { createBooking } from "gql/mutations/booking/createTravelDayBooking";

interface GqlData {
  createTravelDayBooking: BookingType;
}

interface GqlVars {
  booking: CreateTravelDayBookingTypeInput;
}

export function useCreateTravelDayBooking() {
  return useMutation<GqlData, GqlVars>(createBooking)[0];
}
