import { TextField, Typography } from "@mui/material";
import i18n from "i18n";
import { DatePicker as MuiDatePicker, PickersDay } from "@mui/x-date-pickers";
import { DateUtils } from "shared/utils/date";
import { OrganizationHelpers } from "shared/utils/organization";
import { useSharedData } from "shared/state";

interface DatePickerProps {
  caption?: String;
  initialValue?: Date;
  minDate?: Date;
  onChange?: (value: Date) => void;
}

function invalidRange(date: Date, min?: Date): boolean {
  let disabled = false;
  if (min) {
    disabled =
      date.getFullYear() <= min.getFullYear() &&
      date.getMonth() <= min.getMonth() &&
      date.getDate() < min.getDate();
  }
  return disabled;
}

export default function DatePicker({
  caption = i18n.t(["Calendar.Select.Date"]) as string,
  initialValue = new Date(),
  minDate,
  onChange,
}: DatePickerProps) {
  const { state } = useSharedData();

  return (
    <>
      <Typography variant="caption" display="block" gutterBottom>
        {caption}
      </Typography>
      <MuiDatePicker
        inputFormat={DateUtils.DATE_FORMAT}
        value={initialValue}
        onChange={(value) => {
          if (value) {
            onChange?.(DateUtils.toFullHour(value));
          }
        }}
        renderInput={(params) => <TextField {...params} />}
        renderDay={(day, selected, PickerProps) => (
          <PickersDay
            {...PickerProps}
            disabled={
              OrganizationHelpers.isUnavailableDay(state.myOrganization, day) ||
              invalidRange(day, minDate)
            }
          />
        )}
      />
    </>
  );
}
