import { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { AreaType, DeskType } from "api/schema";
import { ModalType, useSharedData } from "shared/state";
import { ToggleCard } from "shared/components/cards/ToggleCard";
import { Desk, MeetingRoom } from "shared/icons/BookingItem";
import { useDesksById } from "api/queries/desks";
import { useAreasById } from "api/queries/areas/areasById";
import { getItemNumber } from "shared/utils/dropdown";

type BookingType = "desk" | "meeting";

function getIcon(bookingType: BookingType) {
  switch (bookingType) {
    case "desk":
      return <Desk />;
    case "meeting":
      return <MeetingRoom />;
    default:
      return undefined;
  }
}

const ContentConteainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(150px, 30%))",
  gap: 20,
});

function getAreaDescription(area: AreaType): string {
  return [
    area.floor?.building?.name,
    area.floor?.floorLabel,
    area.floor?.building?.address,
  ]
    .filter(Boolean)
    .join(", ");
}

function FavouriteDesks({ desks }: { desks: Array<AreaType & DeskType> }) {
  const shared = useSharedData();
  const { t } = useTranslation();

  const modalItem = shared?.state?.modalItem as
    | (AreaType & DeskType)
    | undefined;

  return (
    <>
      <Typography variant="caption" display="block" gutterBottom>
        {t("Home.Favourites.Select")}
      </Typography>
      {desks.length > 0 ? (
        <ContentConteainer>
          {desks.map((area) => (
            <ToggleCard
              key={area.id}
              image={getIcon("desk")}
              title={getItemNumber(area)}
              subtitle={getAreaDescription(area)}
              isActive={area.id === modalItem?.id}
              onToggle={() => {
                const modal = ModalType.BOOK_DESK;
                shared.dispatch(shared.Actions.setModalItem(area));
                shared.dispatch(shared.Actions.openModal(modal));
              }}
            />
          ))}
        </ContentConteainer>
      ) : (
        <Typography variant="body2" display="block" gutterBottom>
          {t("Home.Favourites.Error.NoDesks")}
        </Typography>
      )}
    </>
  );
}

function FavouriteMeetingRooms({
  meetingRooms,
}: {
  meetingRooms: Array<AreaType>;
}) {
  const shared = useSharedData();
  const { t } = useTranslation();

  const modalItem = shared?.state?.modalItem as AreaType | undefined;
  return (
    <>
      <Typography variant="caption" display="block" gutterBottom>
        {t("Home.Favourites.Select")}
      </Typography>
      {meetingRooms.length > 0 ? (
        <ContentConteainer>
          {meetingRooms.map((area) => (
            <ToggleCard
              key={area.id}
              image={getIcon("meeting")}
              title={area.name || area.id}
              subtitle={getAreaDescription(area)}
              isActive={area.id === modalItem?.id}
              onToggle={() => {
                const modal = ModalType.BOOK_MEETING_ROOM;
                shared.dispatch(shared.Actions.setModalItem(area));
                shared.dispatch(shared.Actions.openModal(modal));
              }}
            />
          ))}
        </ContentConteainer>
      ) : (
        <Typography variant="body2" display="block" gutterBottom>
          {t("Home.Favourites.Error.NoMeetingRooms")}
        </Typography>
      )}
    </>
  );
}

interface FavouritePickerProps {
  bookingType?: BookingType;
}

export default function FavouritePicker({ bookingType }: FavouritePickerProps) {
  const shared = useSharedData();
  const { t } = useTranslation();
  const [favMeetingRooms, setFavMeetingRooms] = useState<Array<AreaType>>([]);
  const [favDesks, setFavDesks] = useState<Array<AreaType & DeskType>>([]);

  useDesksById(
    (data) => {
      setFavDesks(data);
    },
    {
      ids: shared.state.user?.userprofile.favouriteDesks ?? [],
      skip: Boolean(favDesks.length),
    },
  );

  useAreasById(
    (data) => {
      const dataCombined = favDesks.map((e) => ({
        ...e,
        ...data.find((el) => el.id === e.areaId),
        id: e.id,
      }));
      setFavDesks(dataCombined);
    },
    {
      areasIds: favDesks.map((e) => e.areaId) ?? [],
    },
  );

  useAreasById(
    (data) => {
      setFavMeetingRooms(data);
    },
    {
      areasIds: shared.state.user?.userprofile.favouriteMeetingRooms ?? [],
    },
  );

  switch (bookingType) {
    case "desk":
      return <FavouriteDesks desks={favDesks} />;
    case "meeting":
      return <FavouriteMeetingRooms meetingRooms={favMeetingRooms} />;
    default:
      return (
        <>
          {favDesks.length > 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  {t("Home.Structure.Floor.Area.Desks.Desk.empty_label")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FavouriteDesks desks={favDesks} />
              </AccordionDetails>
            </Accordion>
          )}
          {favMeetingRooms.length > 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  {t("Home.Structure.Title.meeting_room")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FavouriteMeetingRooms meetingRooms={favMeetingRooms} />
              </AccordionDetails>
            </Accordion>
          )}
        </>
      );
  }
}
