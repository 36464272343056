import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useSharedData } from "shared/state";
import LoadingComponent from "shared/components/LoadingComponent";
import { BaseEntity } from "api/schema";

interface SelectOptionsProps {
  placeholder: String;
  notAvailablePlaceholder: String;
  disabled?: Boolean;
  state: BaseEntity[];
  isLoading?: boolean;
  value: String;
  setValue: (value: any) => void;
  getOptions: () => JSX.Element[];
}

export default function SelectOptions({
  placeholder,
  notAvailablePlaceholder,
  disabled,
  state,
  isLoading,
  value,
  setValue,
  getOptions,
}: SelectOptionsProps) {
  const shared = useSharedData();

  return (
    <>
      <Typography variant="caption" display="block" gutterBottom>
        {placeholder}
      </Typography>
      <FormControl disabled={Boolean(disabled)}>
        {!value && (
          <InputLabel shrink variant="filled" margin="dense">
            {placeholder}...
          </InputLabel>
        )}
        <Select
          value={value}
          onChange={(e) => {
            setValue(state.find((el: BaseEntity) => el.id === e.target.value));
            shared.dispatch(shared.Actions.setModalItem());
          }}
        >
          {isLoading && <LoadingComponent />}
          {!getOptions().length ? (
            <MenuItem value="">{notAvailablePlaceholder}</MenuItem>
          ) : (
            <MenuItem disabled value="">
              {placeholder}
            </MenuItem>
          )}
          {getOptions()}
        </Select>
      </FormControl>
    </>
  );
}
