import { CSSInterpolation, darken } from "@mui/material/styles";
import { ComponentsProps } from "../models";

export const button: ComponentsProps = {
  MuiButton: {
    defaultProps: {
      variant: "outlined",
      color: "inherit",
    },
    styleOverrides: {
      root: {
        fontWeight: 600,
        textTransform: "none",
        lineHeight: "20px",
        padding: "5px 10px",
      },
      outlined: (props) => {
        const styles: CSSInterpolation = {
          backgroundColor: props.theme.palette.grey[900],
          borderColor: props.theme.palette.grey[600],
          color: props.theme.palette.text.primary,
          "&:hover": {
            backgroundColor: darken(
              props.theme.palette.background.default,
              0.05,
            ),
          },
        };
        if (props.ownerState.disabled) {
          Object.assign(styles, {
            backgroundColor: `${props.theme.palette.grey[900]} !important`,
            borderColor: `currentcolor !important`,
            color: `${props.theme.palette.grey[800]} !important`,
          } as CSSInterpolation);
        }
        return styles;
      },
      outlinedPrimary: (props) => ({
        backgroundColor: props.theme.palette.primary.main,
        borderColor: props.theme.palette.primary.main,
        color: props.theme.palette.primary.contrastText,
        "&:hover": {
          backgroundColor: props.theme.palette.primary.light,
          borderColor: props.theme.palette.primary.light,
          color: props.theme.palette.primary.contrastText,
        },
      }),
      outlinedSecondary: (props) => ({
        color: props.theme.palette.text.primary,
        borderColor: props.theme.palette.grey[400],
        "&:hover": {
          borderColor: props.theme.palette.grey[400],
        },
      }),
    },
  },
};
