import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import English from "./en.json";
import German from "./de.json";

export enum SupportedLanguage {
  EN = "en",
  DE = "de",
}

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  resources: {
    [SupportedLanguage.EN]: {
      translation: English,
    },
    [SupportedLanguage.DE]: {
      translation: German,
    },
  },
});

export default i18n;
