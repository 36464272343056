import { gql } from "@apollo/client";

export const getMyActiveBookings = gql`
  query getMyActiveBookings($start: DateTime, $end: DateTime) {
    actives: bookings(start: $start, end: $end, status: ACTIVE) {
      ...bookingData
    }

    checkedIn: bookings(
      start: $start
      end: $end
      status: CHECKED_IN
      homeOffice: false
    ) {
      ...bookingData
    }
  }

  fragment bookingData on BookingType {
    id
    status
    checkedIn
    checkedOut
    homeOffice
    travelDay
    vacation
    sickDay
    start
    end
    desk {
      id
      type
      label
      numberOnFloor
    }
    area {
      id
      name
      areaType
      floor {
        floorLabel
        floorNumber
        floorType
        building {
          name
          address
        }
      }
    }
    parking {
      id
      type: combinedType
      label
      numberOnFloor
    }
    catering {
      id
      items {
        quantity
        item {
          title
        }
      }
    }
    equipping {
      id
      items {
        quantity
        item {
          title
        }
      }
    }
  }
`;
