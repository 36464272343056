import { gql } from "@apollo/client";

export const getBuildingStructureForDesks = gql`
  query getBuildingStructureForDesks(
    $buildingId: ID!
    $date: Date!
    $areaType: AreaTypeEnum
  ) {
    building(buildingId: $buildingId) {
      id
      name
      image
      address
      numberOfAvailableDesksHourlyByAreaType(date: $date) {
        areaType
        desksAvailable
        freeSlots
        totalSlots
      }
      floors {
        id
        floorLabel
        floorNumber
        floorType
        areas(areaType: $areaType) {
          id
          areaType
          image
          name
          isBookable
          numberOfAvailableDesksHourlyByAreaType(date: $date) {
            areaType
            desksAvailable
            freeSlots
            totalSlots
          }
          numberOfDesksByAreaType {
            areaType
            numberOfDesks
          }
        }
        numberOfAvailableDesksHourlyByAreaType(date: $date) {
          areaType
          desksAvailable
          freeSlots
          totalSlots
        }
        numberOfDesksByAreaType {
          areaType
          numberOfDesks
        }
      }
    }
  }
`;
