import { AreaType, FloorType, BuildingType, ParkingType } from "api/schema";
import { startOfToday } from "date-fns";

export interface State {
  selectedDate: Date;
  selectedBuilding?: BuildingType;
  floors: FloorType[];
  selectedFloor?: FloorType;
  areas: AreaType[];
  selectedArea?: AreaType;
  parkingSpots: ParkingType[];
  selectedParkingSpot?: ParkingType;
  hoursStart?: number;
  hoursEnd?: number;
  unavailableSlots: number[];
}

export const initialState: State = {
  selectedDate: startOfToday(),
  floors: [],
  areas: [],
  parkingSpots: [],
  unavailableSlots: [],
};

export enum ActionType {
  RESET,
  SELECT_BUILDING,
  SELECT_DATE,
  SET_FLOORS,
  SELECT_FLOOR,
  SET_AREAS,
  SELECT_AREA,
  SET_PARKING_SPOTS,
  SELECT_PARKING_SPOT,
  SET_HOURS_RANGE,
  SET_UNAVAILABLE_SLOTS,
}

export interface Action {
  type: ActionType;
  payload?: unknown;
}
