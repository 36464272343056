import { useMutation } from "@apollo/client";
import { BookingType, CreateParkingBookingTypeInput } from "api/schema";
import { createBooking } from "gql/mutations/booking/createParkingBooking";

interface GqlData {
  createParkingBooking: BookingType;
}

interface GqlVars {
  booking: CreateParkingBookingTypeInput;
}

export function useCreateParkingBooking() {
  return useMutation<GqlData, GqlVars>(createBooking)[0];
}
