import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { userAttendances } from "gql/queries/attendance/userAttendances";
import { UserAttendanceType, UserAttendanceBreakType } from "api/schema";
import { DateUtils } from "shared/utils/date";

interface GqlData {
  userAttendanceBreaks: UserAttendanceBreakType[];
  userAttendances: UserAttendanceType[];
}

interface GqlVars {
  startDate: string;
  endDate: string;
}

type OnSettled = (data: UserAttendanceType[]) => void;

export function useUserAttendances(onSettled?: OnSettled) {
  const q = useQuery<GqlData, GqlVars>(userAttendances, {
    variables: {
      startDate: DateUtils.formatDate(new Date()),
      endDate: DateUtils.formatDate(new Date()),
    },
  });

  useEffect(() => {
    if (q.data) {
      const sorted = [...q.data.userAttendances].sort(
        (a, b) => new Date(b.endTime).getTime() - new Date(a.endTime).getTime(),
      );
      onSettled?.(sorted);
    }
  }, [q.data]);

  return q;
}
