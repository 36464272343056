import {
  ResourceBookingStatus,
  BookingType,
  DeskType,
  ParkingType,
  AreaType,
} from "api/schema";
import i18n from "i18n";
import BookMeetingRoom from "assets/icons/BookMeetingRoom.svg";
import BookFreeSpace from "assets/icons/BookFreeSpace.svg";
import BookParkingSpot from "assets/icons/BookParkingSpot.svg";
import BookTelephoneBox from "assets/icons/BookTelephoneBox.svg";
import SickDay from "assets/icons/SickDay.svg";
import WorkFromHome from "assets/icons/WorkFromHome.svg";
import BookDesk from "assets/icons/BookDesk.svg";
import Vacation from "assets/icons/Vacation.svg";
import BusinessTravel from "assets/icons/BusinessTravel.svg";
import { ModalType } from "shared/state";

export namespace BookingStatusUtil {
  export const AvailabilityFill: Record<ResourceBookingStatus, string> = {
    FREE: "booking-status--free",
    BLOCKED: "booking-status--blocked",
    BOOKED: "booking-status--booked",
    SELF_BOOKED: "booking-status--self-booked",
  };

  export function getDeskStatus(
    desk: DeskType,
    myBookings: BookingType[],
  ): ResourceBookingStatus {
    const myDesk = myBookings.find((b) => b.desk?.id === desk.id);
    if (myDesk) {
      return "SELF_BOOKED";
    }
    if (desk.blocked || desk.isVipForbidden) {
      return "BLOCKED";
    }
    if (desk.isFree && desk.isOccupied) {
      return "BLOCKED";
    }
    if (desk.isFree) {
      return "FREE";
    }
    return "BOOKED";
  }

  export function getParkingStatus(
    parking: ParkingType,
    myBookings: BookingType[],
  ): ResourceBookingStatus {
    const myParking = myBookings.find((b) => b.parking?.id === parking.id);
    if (myParking) {
      return "SELF_BOOKED";
    }
    if (parking.blocked || parking.isVipForbidden) {
      return "BLOCKED";
    }
    if (parking.isFree && parking.isOccupied) {
      return "BLOCKED";
    }
    if (parking.isFree) {
      return "FREE";
    }
    return "BOOKED";
  }

  export function getMeetingRoomStatus(
    meetingRoom: AreaType,
    myBookings: BookingType[],
  ): ResourceBookingStatus {
    const myBookedArea = myBookings.find((b) => b.area?.id === meetingRoom.id);
    let booked: number = 0;
    let total: number = 0;
    if (myBookedArea) {
      return "SELF_BOOKED";
    }
    if (!meetingRoom.isMeetingRoomFree || meetingRoom.isMeetingRoomOccupied) {
      return "BLOCKED";
    }
    if (meetingRoom.meetingRoomTimeStats) {
      booked = meetingRoom.meetingRoomTimeStats.booked;
      total = meetingRoom.meetingRoomTimeStats.total;
    }
    if (booked > 0) {
      return total - booked > 0 ? "BOOKED" : "BLOCKED";
    }
    return "FREE";
  }

  export function getTelBoxStatus(
    telephoneBox: AreaType,
    myBookings: BookingType[],
  ): ResourceBookingStatus {
    const myBookedArea = myBookings.find((b) => b.area?.id === telephoneBox.id);
    if (myBookedArea) {
      return "SELF_BOOKED";
    }
    if (!telephoneBox.isTelefonBoxFree) {
      return "BLOCKED";
    }
    const { total = 0, booked = 0 } = telephoneBox.telefonBoxTimeStats;
    if (booked > 0) {
      return total - booked > 0 ? "BOOKED" : "BLOCKED";
    }
    return "FREE";
  }

  export function getFreeSpaceStatus(
    freeSpace: AreaType,
    myBookings: BookingType[],
  ): ResourceBookingStatus {
    const myBookedArea = myBookings.find((b) => b.area?.id === freeSpace.id);
    if (myBookedArea) {
      return "SELF_BOOKED";
    }
    return "FREE";
  }

  export function getBookingStatus(
    booking: BookingType,
    myBookings: BookingType[],
  ): ResourceBookingStatus {
    switch (booking.area.areaType) {
      case "room": {
        // desk
        return getDeskStatus(booking.desk, myBookings);
      }
      case "parking": {
        return getParkingStatus(booking.parking, myBookings);
      }
      case "meeting_room": {
        return getMeetingRoomStatus(booking.area, myBookings);
      }
      case "telefon_box": {
        return getTelBoxStatus(booking.area, myBookings);
      }
      case "free_spaces_room": {
        return getFreeSpaceStatus(booking.area, myBookings);
      }
      default:
        return "FREE";
    }
  }

  export function getStatusColor(status: ResourceBookingStatus): string {
    return AvailabilityFill[status];
  }

  export function getAreaModalType(area: AreaType): ModalType {
    switch (area.areaType) {
      case "telefon_box":
        return ModalType.BOOK_TELEPHONE_BOX;
      case "free_spaces_room":
        return ModalType.BOOK_FREE_SPACE;
      default:
        return ModalType.BOOK_MEETING_ROOM;
    }
  }

  export function getBookingType(booking: BookingType): [string, string] {
    let image = BookMeetingRoom;
    let title = i18n.t("Home.ActiveBookings.Booking.title.meeting_room");
    if (booking.area?.areaType === "free_spaces_room") {
      image = BookFreeSpace;
      title = i18n.t("Home.ActiveBookings.Booking.title.FreeSpace");
    } else if (booking.area?.areaType === "telefon_box") {
      image = BookTelephoneBox;
      title = i18n.t("Home.ActiveBookings.Booking.title.telefonBox");
    } else if (booking.desk) {
      image = BookDesk;
      title = i18n.t("Home.Structure.Floor.Area.Desks.Desk.empty_label");
    } else if (booking.parking) {
      image = BookParkingSpot;
      title = i18n.t("Home.ActiveBookings.Booking.title.parkingSpot");
    } else if (booking.vacation) {
      image = Vacation;
      title = i18n.t("Home.ActiveBookings.Booking.title.vacation");
    } else if (booking.travelDay) {
      image = BusinessTravel;
      title = i18n.t("Home.ActiveBookings.Booking.title.travelDay");
    } else if (booking.homeOffice) {
      image = WorkFromHome;
      title = i18n.t("Home.ActiveBookings.Booking.title.home");
    } else if (booking.sickDay) {
      image = SickDay;
      title = i18n.t("Home.ActiveBookings.Booking.title.sickday");
    }
    return [image, title];
  }
}
