import { useReducer, useMemo, useEffect } from "react";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import WorkFromHome from "assets/icons/WorkFromHome.svg";
import { useSharedData } from "shared/state";
import { filterActiveBookings } from "shared/utils/filter-booking";
import { useMyActiveBookings } from "api/queries/bookings/myActiveBookings";
import { useCreateHomeOfficeBooking } from "api/mutations/booking/createHomeOfficeBooking";
import { DateUtils, TimeSlot } from "shared/utils/date";
import { OrganizationHelpers } from "shared/utils/organization";
import DialogHeader from "../components/DialogHeader";
import DialogWrapper from "../components/DialogWrapper";
import DatePicker from "../components/DatePicker";
import TimePickersWrapper from "../components/TimePickers";
import ErrorMessage from "../components/ErrorMessage";
import { initialState, reducer, Actions, WorkFromHomeContext } from "./state";

export function WorkFromHomeModal() {
  const shared = useSharedData();
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const context = useMemo(() => ({ state, dispatch, Actions }), [state]);
  const hourlyBookingFlag =
    shared.state.myOrganization?.enabledFeatures.includes("hourly_booking");

  const minmax = useMemo<TimeSlot>(() => {
    if (shared.state.myOrganization) {
      const minmaxF = [
        shared.state.myOrganization.workingHoursStart,
        shared.state.myOrganization.workingHoursEnd,
      ].map((value) =>
        OrganizationHelpers.parseOrganizationTime(value.toString()),
      ) as TimeSlot;
      return minmaxF;
    }
    return [0, 0];
  }, [shared.state.myOrganization]);

  useEffect(() => {
    dispatch(Actions.setHoursRange(minmax));
    dispatch(Actions.selectDate(shared.state.modalInitialDate));
  }, [minmax, shared.state.modalInitialDate]);

  const createBooking = useCreateHomeOfficeBooking();

  const { refetch: refetchActiveBookings } = useMyActiveBookings(
    (data) => {
      const filteredByWFH = filterActiveBookings(data, "home-office");
      dispatch(Actions.setUnavailableSlots(filteredByWFH));
    },
    {
      start: state.selectedDate,
      end: state.selectedDate,
    },
  );

  useEffect(() => {
    refetchActiveBookings().then(() => {
      shared.dispatch(shared.Actions.setLoading(false));
    });
  }, [state.selectedDate]);

  return (
    <WorkFromHomeContext.Provider value={context}>
      <DialogWrapper open>
        <DialogHeader
          icon={WorkFromHome}
          title={t("Home.Bookings.HomeOffice.title")}
          subheader={t("Home.ActiveBooking.Booking.Popup.SubTitle")}
        />
        <DialogContent>
          <DatePicker
            caption={t("Home.ActiveBooking.Booking.WFH") as string}
            initialValue={state?.selectedDate}
            onChange={(value) => {
              dispatch(Actions.selectDate(value));
            }}
          />
          {hourlyBookingFlag && (
            <TimePickersWrapper
              selectedDate={state?.selectedDate}
              hoursStart={state?.hoursStart}
              hoursEnd={state?.hoursEnd}
              onChange={(start, end) => {
                dispatch(Actions.setHoursRange([start, end]));
              }}
              setHourRange={(timeSlot) =>
                dispatch(Actions.setHoursRange(timeSlot))
              }
              unavailableSlots={state?.unavailableSlots}
              minmax={minmax}
              isDisabled={false}
            />
          )}
          {state.hoursStart === state.hoursEnd && <ErrorMessage />}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={state.hoursEnd === state.hoursStart}
            onClick={() => {
              createBooking({
                variables: {
                  booking: {
                    start: DateUtils.setFullUTCHour(
                      state.selectedDate,
                      state.hoursStart ?? 0,
                    ),
                    end: DateUtils.setFullUTCHour(
                      state.selectedDate,
                      state.hoursEnd ?? 0,
                    ),
                    comment: "",
                  },
                },
              })
                .then(() => {
                  shared.dispatch(shared.Actions.setLoading(true));
                  shared.dispatch(
                    shared.Actions.notifySuccess(
                      t(["Home.ActiveBooking.Booking.title"]),
                    ),
                  );
                })
                .catch((error) => {
                  shared.dispatch(
                    shared.Actions.notifyError(
                      error?.message || t(["Errors.error_title"]),
                    ),
                  );
                });
              shared.dispatch(shared.Actions.openModal());
            }}
          >
            {t(["Routes.Home.Book.Confirm"])}
          </Button>
        </DialogActions>
      </DialogWrapper>
    </WorkFromHomeContext.Provider>
  );
}
