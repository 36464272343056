import { AreaType, FloorType, BuildingType, DeskType } from "api/schema";
import { DateUtils, TimeSlot } from "shared/utils/date";
import { Action, ActionType } from "./definitions";

export namespace Actions {
  export function reset(): Action {
    return {
      type: ActionType.RESET,
    };
  }

  export function selectDate(date?: Date | null): Action {
    return {
      type: ActionType.SELECT_DATE,
      payload: DateUtils.toFullHour(date ?? new Date()),
    };
  }

  export function selectBuilding(building?: BuildingType): Action {
    return {
      type: ActionType.SELECT_BUILDING,
      payload: building,
    };
  }

  export function setFloors(floors?: FloorType[]): Action {
    return {
      type: ActionType.SET_FLOORS,
      payload: floors,
    };
  }

  export function selectFloor(floor?: FloorType): Action {
    return {
      type: ActionType.SELECT_FLOOR,
      payload: floor,
    };
  }

  export function setAreas(areas?: AreaType[]): Action {
    return {
      type: ActionType.SET_AREAS,
      payload: areas,
    };
  }

  export function selectArea(area?: AreaType): Action {
    return {
      type: ActionType.SELECT_AREA,
      payload: area,
    };
  }

  export function setDesks(desks?: DeskType[]): Action {
    return {
      type: ActionType.SET_DESKS,
      payload: desks,
    };
  }

  export function selectDesk(desk?: DeskType): Action {
    return {
      type: ActionType.SELECT_DESK,
      payload: desk,
    };
  }

  export function setUnavailableSlots(arr?: number[]): Action {
    return {
      type: ActionType.SET_UNAVAILABLE_SLOTS,
      payload: Array.isArray(arr) ? arr : [],
    };
  }

  export function setHoursRange(value?: TimeSlot): Action {
    return {
      type: ActionType.SET_HOURS_RANGE,
      payload: value ?? [0, 0],
    };
  }

  export function setIsFavDesk(value?: boolean): Action {
    return {
      type: ActionType.SET_IS_FAVOURITE_DESK,
      payload: value,
    };
  }
}
