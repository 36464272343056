import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useSharedData } from "shared/state";
import { BuildingType } from "api/schema";
import { getBuildings } from "gql/queries/buildingsInfo";

interface GqlData {
  buildingsInfo: BuildingType[];
}

interface GqlVars {}

type OnSettled = (data: BuildingType[]) => void;

export function useBuildingsInfo(onSettled?: OnSettled) {
  const q = useQuery<GqlData, GqlVars>(getBuildings);
  const { state } = useSharedData();
  const defaultBuilding = state.user?.userprofile.defaultBuilding;

  useEffect(() => {
    if (q.data?.buildingsInfo) {
      const sorted = [...q.data.buildingsInfo].sort((a, b) => {
        if (a.id === defaultBuilding?.id) {
          return -1;
        }
        if (b.id === defaultBuilding?.id) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      });
      onSettled?.(sorted);
    }
  }, [q.data, defaultBuilding]);

  return q;
}
