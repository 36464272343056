import { BuildingType, FloorType, AreaType } from "api/schema";
import { TimeSlot } from "shared/utils/date";
import { State, initialState, Action, ActionType } from "./definitions";

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.RESET: {
      return {
        ...initialState,
      };
    }
    case ActionType.SELECT_DATE: {
      return {
        ...state,
        selectedDate: action.payload as Date,
      };
    }
    case ActionType.SELECT_BUILDING: {
      return {
        ...state,
        selectedBuilding: action.payload as BuildingType,
        floors: [],
        selectedFloor: undefined,
        freeSpaces: [],
        selectedFreeSpace: undefined,
      };
    }
    case ActionType.SET_FLOORS: {
      const floors = action.payload as FloorType[];
      const selectedFloor = floors.find(
        (f) => f.id === state.selectedFloor?.id,
      );
      return {
        ...state,
        floors,
        selectedFloor,
        freeSpaces: [],
        selectedFreeSpace: undefined,
      };
    }
    case ActionType.SELECT_FLOOR: {
      return {
        ...state,
        selectedFloor: action.payload as FloorType,
        freeSpaces: [],
        selectedFreeSpace: undefined,
      };
    }
    case ActionType.SET_FREE_SPACES: {
      const freeSpaces = action.payload as AreaType[];
      const selectedFreeSpace = freeSpaces.find(
        (r) => r.id === state.selectedFreeSpace?.id,
      );
      return {
        ...state,
        freeSpaces,
        selectedFreeSpace,
      };
    }
    case ActionType.SELECT_FREE_SPACE: {
      return {
        ...state,
        selectedFreeSpace: action.payload as AreaType,
      };
    }
    case ActionType.SET_UNAVAILABLE_SLOTS: {
      return {
        ...state,
        unavailableSlots: action.payload as number[],
      };
    }
    case ActionType.SET_HOURS_RANGE: {
      const [start, end] = action.payload as TimeSlot;
      return {
        ...state,
        hoursStart: start,
        hoursEnd: end,
      };
    }
    default:
      throw new Error("Unknown action");
  }
}
