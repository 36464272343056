import { Typography, FormControlLabel, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAddFavourite } from "api/mutations/favourites/addFavourite";
import { useRemoveFavourite } from "api/mutations/favourites/removeFavourite";
import { useSharedData } from "shared/state";

type FavouriteTypeEnum = "desk" | "area";

interface SelectFavouriteProps {
  favouriteType: FavouriteTypeEnum;
  favouriteId: string;
  checked: boolean;
  onSelect: (checked: boolean) => void;
}

export default function SelectFavourite({
  favouriteType,
  favouriteId,
  checked,
  onSelect,
}: SelectFavouriteProps) {
  const shared = useSharedData();
  const { t } = useTranslation();

  const addToFavourites = useAddFavourite();
  const removeFromFavourites = useRemoveFavourite();

  const toggleFavourite = (type: boolean) => {
    onSelect(type);
    if (type) {
      addToFavourites({
        variables: {
          favouriteId,
          favouriteType,
        },
      }).then(() => {
        shared.dispatch(shared.Actions.setLoading(true));
      });
    } else {
      removeFromFavourites({
        variables: {
          favouriteId,
          favouriteType,
        },
      }).then(() => {
        shared.dispatch(shared.Actions.setLoading(true));
      });
    }
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          disabled={!favouriteId}
          size="small"
          onChange={(e) => {
            toggleFavourite(e.target.checked);
          }}
        />
      }
      label={
        <Typography variant="body2" color="textSecondary">
          {t("Home.Favourites.mark")}
        </Typography>
      }
    />
  );
}
