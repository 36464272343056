import { DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import Favourites from "assets/icons/Favourites.svg";
import NoFavourites from "assets/icons/NoFavourites.svg";
import { useSharedData } from "shared/state";
import { PageContentEmpty } from "shared/components/page";
import { DialogHeader, DialogWrapper, FavouritePicker } from "./components";

export function FavouritesModal() {
  const { state } = useSharedData();
  const { t } = useTranslation();

  return (
    <DialogWrapper open>
      <DialogHeader
        icon={Favourites}
        title={t("Home.Favourites.MyFavourites")}
        subheader={t("Home.ActiveBooking.Booking.Popup.SubTitle")}
      />
      <DialogContent>
        {state.hasFavourites ? (
          <FavouritePicker />
        ) : (
          <PageContentEmpty
            image={NoFavourites}
            title={t("Home.Favourites.no-favs")}
            subtitle={t("Floorplan.NoData.MoreInfo")}
          />
        )}
      </DialogContent>
    </DialogWrapper>
  );
}
