import { MenuItem } from "@mui/material";
import { DeskType, ParkingType } from "api/schema";
import { State as DesksState } from "../components/modals/book-desk/state/definitions";
import { State as ParkingState } from "../components/modals/book-parking-spot/state/definitions";
import { State as TelephoneBoxState } from "../components/modals/book-telephone-box/state/definitions";
import { State as MeetingsState } from "../components/modals/book-meeting-room/state/definitions";
import { State as FreeSpaceState } from "../components/modals/book-free-space/state/definitions";
import { State as SharedState } from "../state/definitions";
import { State as FloorState } from "../../modules/floor/state/definitions";

type State =
  | DesksState
  | ParkingState
  | MeetingsState
  | TelephoneBoxState
  | FreeSpaceState
  | SharedState
  | FloorState;

// Checking if a property is in state is required to narrow down the State union
export const getAreaOptions = (state: State, areaType: string) => {
  if ("selectedFloor" in state) {
    const areas = state.selectedFloor?.areas
      .filter((e) => e.areaType === areaType)
      .map((e) => (
        <MenuItem value={e.id} key={e.id}>
          {e.name}
        </MenuItem>
      ));

    return areas?.length ? areas : [];
  }

  return [];
};

export function getItemNumber(item: DeskType | ParkingType): string {
  const numberOnFloor = item.numberOnFloor ?? -1;
  const num = numberOnFloor < 0 ? "" : numberOnFloor.toString();
  return num || item.label || item.id || "";
}

export const getDeskAreaOptions = (state: State) => {
  if ("desks" in state) {
    const desks = state.desks
      .filter((e) => e.isFree || !e.isForbidden || !e.isOccupied)
      .map((e) => (
        <MenuItem value={e.id} key={e.id}>
          {getItemNumber(e)}
        </MenuItem>
      ));

    return desks?.length ? desks : [];
  }

  return [];
};

export const getFloorOptions = (state: State) => {
  if ("floors" in state) {
    const floors = state.floors.map((e) => (
      <MenuItem value={e.id} key={e.id}>
        {e.floorLabel}
      </MenuItem>
    ));

    return floors?.length ? floors : [];
  }

  return [];
};

export const getBuildingOptions = (state: State) => {
  if ("buildings" in state) {
    const buildings = state.buildings.map((building) => (
      <MenuItem value={building.id} key={building.id}>
        {building.name}
      </MenuItem>
    ));

    return buildings?.length ? buildings : [];
  }

  return [];
};

export const getParkingSpots = (state: State) => {
  if ("parkingSpots" in state) {
    const parkingSpots = state.parkingSpots
      .filter((e) => e.isFree || !e.isForbidden || !e.isOccupied)
      .map((e) => (
        <MenuItem value={e.id} key={e.id}>
          {getItemNumber(e)}
        </MenuItem>
      ));

    return parkingSpots?.length ? parkingSpots : [];
  }

  return [];
};

export const getMeetingRooms = (state: State) => {
  if ("meetingRooms" in state) {
    const meetingRooms = state.meetingRooms.map((e) => (
      <MenuItem value={e.id} key={e.id}>
        {e.name}
      </MenuItem>
    ));

    return meetingRooms?.length ? meetingRooms : [];
  }

  return [];
};

export const getFreeSpaceAreas = (state: State) => {
  if ("freeSpaces" in state) {
    const areas = state.freeSpaces.map((e) => (
      <MenuItem value={e.id} key={e.id}>
        {e.name}
      </MenuItem>
    ));

    return areas?.length ? areas : [];
  }

  return [];
};

export const getTelephoneBoxes = (state: State) => {
  if ("telephoneBoxes" in state) {
    const telBoxes = state.telephoneBoxes.map((e) => (
      <MenuItem value={e.id} key={e.id}>
        {e.name}
      </MenuItem>
    ));

    return telBoxes?.length ? telBoxes : [];
  }

  return [];
};
