// https://mui.com/x/react-date-pickers/getting-started/

import type {} from "@mui/x-date-pickers/themeAugmentation";
import { ComponentsProps } from "../models";

export const datePicker: ComponentsProps = {
  MuiDatePicker: {
    defaultProps: {},
    styleOverrides: {},
  },
};
