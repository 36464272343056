import { useMemo, useReducer, useEffect } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DateUtils, TimeSlot } from "shared/utils/date";
import { OrganizationHelpers } from "shared/utils/organization";
import BookDesk from "assets/icons/BookDesk.svg";
import { AreaType, DeskType } from "api/schema";
import { useMyActiveBookings } from "api/queries/bookings/myActiveBookings";
import { useBuildingStructureForDesks } from "api/queries/building/buildingStructureForDesks";
import { useUnavailableTimePeriods } from "api/queries/bookings/unavailableTimePeriods";
import { useAreaDesks } from "api/queries/areas/areasDesks";
import { useCreateBooking } from "api/mutations/booking/createBooking";
import { useSharedData } from "shared/state";
import { filterActiveBookings } from "shared/utils/filter-booking";
import { BookDeskContext, initialState, reducer, Actions } from "./state";
import {
  DialogHeader,
  TimePickers,
  DatePicker,
  DialogWrapper,
  ErrorMessage,
  PeriodPicker,
  FavouritePicker,
  SelectOptions,
  SelectFavourite,
} from "../components";
import {
  getBuildingOptions,
  getDeskAreaOptions,
  getFloorOptions,
  getAreaOptions,
} from "../../../utils/dropdown";

export default function BookDeskModal() {
  const shared = useSharedData();
  const modalItem = shared?.state?.modalItem as
    | (AreaType & DeskType)
    | undefined;
  const [state, dispatch] = useReducer(reducer, initialState);
  const context = useMemo(() => ({ state, dispatch, Actions }), [state]);

  const featureFlags = shared.state.myOrganization?.enabledFeatures;
  const { t } = useTranslation();

  const createBooking = useCreateBooking();

  const minmax = useMemo<TimeSlot>(() => {
    if (shared.state.myOrganization) {
      return [
        shared.state.myOrganization.workingHoursStart,
        shared.state.myOrganization.workingHoursEnd,
      ].map((value) =>
        OrganizationHelpers.parseOrganizationTime(value.toString()),
      ) as TimeSlot;
    }
    return [0, 0];
  }, [shared.state.myOrganization]);

  useEffect(() => {
    dispatch(Actions.setHoursRange(minmax));
  }, [minmax]);

  useEffect(() => {
    dispatch(Actions.selectDate(shared.state.modalInitialDate));
  }, [shared.state.modalInitialDate]);

  useEffect(() => {
    if (shared.state.selectedBuilding) {
      if (!state.selectedBuilding) {
        dispatch(Actions.selectBuilding(shared.state.selectedBuilding));
      } else if (typeof modalItem !== "undefined") {
        const building = shared.state.buildings.find(
          (b) => b.id === modalItem?.floor?.building?.id,
        );
        if (state.selectedBuilding.id !== building?.id) {
          dispatch(Actions.selectBuilding(building));
        }
      }
    }
  }, [shared.state.selectedBuilding, modalItem]);

  const { loading: loadingFloors } = useBuildingStructureForDesks(
    (data) => {
      dispatch(
        Actions.setFloors(data?.floors.filter((e) => e.floorType === "mixed")),
      );
    },
    {
      building: state.selectedBuilding,
      date: state.selectedDate,
    },
  );

  useEffect(() => {
    if (state.floors) {
      if (typeof modalItem !== "undefined") {
        const floor = state.floors.find((f) => f.id === modalItem?.floor?.id);
        if (state.selectedFloor?.id !== floor?.id) {
          dispatch(Actions.selectFloor(floor));
        }
      }
    }
  }, [state.floors, modalItem]);

  useEffect(() => {
    if (state.selectedFloor) {
      dispatch(
        Actions.setAreas(
          (state.selectedFloor?.areas || []).filter(
            (e) => e.areaType === "room",
          ),
        ),
      );
    }
  }, [state.selectedFloor]);

  useEffect(() => {
    if (state.areas) {
      if (typeof modalItem !== "undefined") {
        const area = state.areas.find((a) => a.id === modalItem?.areaId);
        if (state.selectedArea?.id !== area?.id) {
          dispatch(Actions.selectArea(area));
        }
      }
    }
  }, [state.areas, modalItem]);

  const { loading: loadingDesks } = useAreaDesks(
    (data) => {
      dispatch(Actions.setDesks(data));
    },
    {
      areas: state?.selectedArea ? [state.selectedArea] : [],
      date: DateUtils.setFullUTCHour(state.selectedDate, 0),
      hoursStart: DateUtils.setFullUTCHour(
        state.selectedDate,
        state.hoursStart ?? 0,
      ),
      hoursEnd: DateUtils.setFullUTCHour(
        state.selectedDate,
        state.hoursEnd ?? 0,
      ),
    },
  );

  useEffect(() => {
    if (state.desks) {
      if (typeof modalItem !== "undefined") {
        const desk = state.desks.find((d) => d.id === modalItem?.id);
        if (state.selectedDesk?.id !== desk?.id) {
          dispatch(Actions.selectDesk(desk));
        }
      }
    }
  }, [state.desks, modalItem]);

  useEffect(() => {
    dispatch(
      Actions.setIsFavDesk(
        Boolean(
          shared.state.user?.userprofile.favouriteDesks.some(
            (e) => e === state.selectedDesk?.id,
          ),
        ),
      ),
    );
  }, [state.selectedDesk, shared.state.user]);

  const isFloorDisabled = !(state.selectedDate && state.selectedBuilding);
  const isAreaDisabled = !state.selectedFloor;
  const isDesksDisabled = !state.selectedArea;
  const isTimePeriodDisabled = !(
    featureFlags?.includes("hourly_booking") && state.selectedDesk
  );

  const { refetch: refetchTimePeriods } = useUnavailableTimePeriods(
    (data) => {
      const combinedData = [
        ...(shared.state.myBookedSlots ?? []),
        ...OrganizationHelpers.getUnavailableTimePeriods(data),
      ].sort((a, b) => a - b);

      const uniqueSlots = Array.from(new Set(combinedData));
      dispatch(Actions.setUnavailableSlots(uniqueSlots));
    },
    {
      date: state.selectedDate,
      areaId: state.selectedArea?.id ?? "",
      deskId: state.selectedDesk?.id ?? "",
      skip: isTimePeriodDisabled || !Array.isArray(shared.state.myBookedSlots),
    },
  );

  const { refetch: refetchActiveBookings } = useMyActiveBookings(
    (data) => {
      const filteredByDesk = filterActiveBookings(data, "desk");
      shared.dispatch(shared.Actions.setMyBookedSlots(filteredByDesk));
    },
    {
      start: state.selectedDate,
      end: state.selectedDate,
    },
  );

  useEffect(() => {
    refetchActiveBookings().then(() => {
      shared.dispatch(shared.Actions.setLoading(false));
    });
  }, [state.selectedDate]);

  useEffect(() => {
    refetchTimePeriods();
  }, [state.unavailableSlots]);

  return (
    <BookDeskContext.Provider value={context}>
      <DialogWrapper open>
        <DialogHeader
          icon={BookDesk}
          title={t("Home.ActiveBooking.Booking.Popup.Title")}
          subheader={t("Home.ActiveBooking.Booking.Popup.SubTitle")}
        />
        <DialogContent>
          <DatePicker
            initialValue={state.selectedDate}
            onChange={(value) => dispatch(Actions.selectDate(value))}
          />
          <FavouritePicker bookingType="desk" />
          <SelectOptions
            placeholder={t("Home.Buildings.Select") as string}
            notAvailablePlaceholder={t("Home.Buildings.NotAvailable") as string}
            state={shared.state.buildings}
            value={state.selectedBuilding?.id ?? ""}
            setValue={(val) => dispatch(Actions.selectBuilding(val))}
            getOptions={() => getBuildingOptions(shared.state)}
          />
          <SelectOptions
            placeholder={t("Filter.Floors.Select") as string}
            notAvailablePlaceholder={t("Filter.Floors.NoFloors") as string}
            disabled={isFloorDisabled}
            state={state.floors}
            isLoading={loadingFloors}
            value={state?.selectedFloor?.id ?? ""}
            setValue={(val) => dispatch(Actions.selectFloor(val))}
            getOptions={() => getFloorOptions(state)}
          />
          <SelectOptions
            placeholder={t(["Filter.Area.Select"]) as string}
            notAvailablePlaceholder={t(["Filter.Area.NoArea"]) as string}
            disabled={isAreaDisabled}
            state={state.areas}
            value={state?.selectedArea?.id ?? ""}
            setValue={(val) => dispatch(Actions.selectArea(val))}
            getOptions={() => getAreaOptions(state, "room")}
          />
          <SelectOptions
            placeholder={t("Filter.Desk.Title") as string}
            notAvailablePlaceholder={t("Filter.Desk.NoDesk") as string}
            disabled={isDesksDisabled}
            isLoading={loadingDesks}
            state={state.desks}
            value={state?.selectedDesk?.id ?? ""}
            setValue={(val) => dispatch(Actions.selectDesk(val))}
            getOptions={() => getDeskAreaOptions(state)}
          />

          {Boolean(state.selectedDesk?.equipment.length) && (
            <Typography variant="subtitle2" display="block">
              {t(["Home.Book.Equipping.Order.Title"])}:&nbsp;
              <Typography variant="caption">
                {(state.selectedDesk?.equipment || [])
                  .map((e) => e.name)
                  .join(", ")}
              </Typography>
            </Typography>
          )}

          <SelectFavourite
            favouriteType="desk"
            favouriteId={state?.selectedDesk?.id ?? ""}
            checked={state.isFavDesk}
            onSelect={(checked) => {
              dispatch(Actions.setIsFavDesk(checked));
            }}
          />

          <Typography variant="caption" display="block" gutterBottom>
            {t(["Time.Slots.selection"])}
          </Typography>
          <PeriodPicker
            bookingType="desk"
            unavailableSlots={state.unavailableSlots}
            selectedStart={state.hoursStart ?? 0}
            selectedEnd={state.hoursEnd ?? 0}
            onSelect={(start, end) => {
              dispatch(Actions.setHoursRange([start, end]));
            }}
          />
          {featureFlags?.includes("hourly_booking") && (
            <TimePickers
              selectedDate={state.selectedDate}
              hoursStart={state.hoursStart}
              hoursEnd={state.hoursEnd}
              onChange={(start, end) => {
                dispatch(Actions.setHoursRange([start, end]));
              }}
              setHourRange={(timeSlot) =>
                dispatch(Actions.setHoursRange(timeSlot))
              }
              unavailableSlots={state.unavailableSlots}
              minmax={minmax}
              isDisabled={isTimePeriodDisabled}
            />
          )}
          {state.hoursStart === state.hoursEnd && <ErrorMessage />}
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            disabled={
              !state.selectedDesk?.id || state.hoursStart === state.hoursEnd
            }
            onClick={() => {
              createBooking({
                variables: {
                  booking: {
                    areaId: state.selectedArea?.id ?? "",
                    deskId: state.selectedDesk?.id ?? "",
                    start: DateUtils.setFullUTCHour(
                      state.selectedDate,
                      state.hoursStart ?? 0,
                    ),
                    end: DateUtils.setFullUTCHour(
                      state.selectedDate,
                      state.hoursEnd ?? 0,
                    ),
                    comment: "",
                  },
                },
              })
                .then(() => {
                  shared.dispatch(shared.Actions.setLoading(true));
                  shared.dispatch(
                    shared.Actions.notifySuccess(
                      t(["Home.ActiveBooking.Booking.title"]),
                    ),
                  );
                })
                .catch((error) => {
                  shared.dispatch(
                    shared.Actions.notifyError(
                      error?.message || t(["Errors.error_title"]),
                    ),
                  );
                });
              shared.dispatch(shared.Actions.openModal());
            }}
          >
            {t(["Routes.Home.Book.Confirm"])}
          </Button>
        </DialogActions>
      </DialogWrapper>
    </BookDeskContext.Provider>
  );
}
