import { ComponentsProps } from "../models";

export const card: ComponentsProps = {
  MuiCard: {
    defaultProps: {
      variant: "outlined",
      color: "inherit",
    },
    styleOverrides: {
      root: {
        fontWeight: 600,
        textTransform: "none",
        lineHeight: "20px",
        border: "none",
      },
    },
  },
};
