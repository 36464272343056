import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function FirstHalfDay(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 12 1 C 11.45 1 11 1.45 11 2 L 11 4 C 11 4.55 11.45 5 12 5 C 12.55 5 13 4.55 13 4 L 13 2 C 13 1.45 12.55 1 12 1 z M 5.28125 4.2871094 C 5.025 4.2871094 4.7700781 4.3850781 4.5800781 4.5800781 C 4.1900781 4.9700781 4.1900781 5.6102344 4.5800781 5.9902344 L 5.640625 7.0507812 C 6.030625 7.4407812 6.6707813 7.4407812 7.0507812 7.0507812 C 7.4307812 6.6607813 7.4407813 6.020625 7.0507812 5.640625 L 5.9902344 4.5800781 C 5.7952344 4.3850781 5.5375 4.2871094 5.28125 4.2871094 z M 12 7 C 9.24 7 7 9.24 7 12 C 7 14.76 9.24 17 12 17 C 12.347826 17 12.687759 16.964193 13.015625 16.896484 L 13.015625 7.1035156 C 12.687759 7.0358068 12.347826 7 12 7 z M 2 11 C 1.45 11 1 11.45 1 12 C 1 12.55 1.45 13 2 13 L 4 13 C 4.55 13 5 12.55 5 12 C 5 11.45 4.55 11 4 11 L 2 11 z M 6.3417969 16.658203 C 6.0855469 16.658203 5.830625 16.754219 5.640625 16.949219 L 4.5800781 18.009766 C 4.1900781 18.399766 4.1900781 19.039922 4.5800781 19.419922 C 4.9700781 19.799922 5.6102344 19.809922 5.9902344 19.419922 L 7.0507812 18.359375 C 7.4407812 17.969375 7.4407812 17.329219 7.0507812 16.949219 C 6.8557812 16.754219 6.5980469 16.658203 6.3417969 16.658203 z M 12 19 C 11.45 19 11 19.45 11 20 L 11 22 C 11 22.55 11.45 23 12 23 C 12.55 23 13 22.55 13 22 L 13 20 C 13 19.45 12.55 19 12 19 z " />
    </SvgIcon>
  );
}

export function FullDay(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zM2 13h2c.55 0 1-.45 1-1s-.45-1-1-1H2c-.55 0-1 .45-1 1s.45 1 1 1zm18 0h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1zM11 2v2c0 .55.45 1 1 1s1-.45 1-1V2c0-.55-.45-1-1-1s-1 .45-1 1zm0 18v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1zM5.99 4.58c-.39-.39-1.03-.39-1.41 0-.39.39-.39 1.03 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0s.39-1.03 0-1.41L5.99 4.58zm12.37 12.37c-.39-.39-1.03-.39-1.41 0-.39.39-.39 1.03 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0 .39-.39.39-1.03 0-1.41l-1.06-1.06zm1.06-10.96c.39-.39.39-1.03 0-1.41-.39-.39-1.03-.39-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06zM7.05 18.36c.39-.39.39-1.03 0-1.41-.39-.39-1.03-.39-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06z" />
    </SvgIcon>
  );
}

export function SecondHalfDay(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 12 1 C 11.45 1 11 1.45 11 2 L 11 4 C 11 4.55 11.45 5 12 5 C 12.55 5 13 4.55 13 4 L 13 2 C 13 1.45 12.55 1 12 1 z M 18.710938 4.2871094 C 18.454687 4.2871094 18.199766 4.3850781 18.009766 4.5800781 L 16.949219 5.640625 C 16.559219 6.030625 16.559219 6.6707813 16.949219 7.0507812 C 17.339219 7.4307812 17.979375 7.4407813 18.359375 7.0507812 L 19.419922 5.9902344 C 19.809922 5.6002344 19.809922 4.9600781 19.419922 4.5800781 C 19.224922 4.3850781 18.967188 4.2871094 18.710938 4.2871094 z M 12 7 C 11.651315 7 11.31104 7.0374336 10.982422 7.1054688 L 10.982422 16.894531 C 11.31104 16.962566 11.651315 17 12 17 C 14.76 17 17 14.76 17 12 C 17 9.24 14.76 7 12 7 z M 20 11 C 19.45 11 19 11.45 19 12 C 19 12.55 19.45 13 20 13 L 22 13 C 22.55 13 23 12.55 23 12 C 23 11.45 22.55 11 22 11 L 20 11 z M 17.650391 16.658203 C 17.394141 16.658203 17.139219 16.754219 16.949219 16.949219 C 16.559219 17.339219 16.559219 17.979375 16.949219 18.359375 L 18.009766 19.419922 C 18.399766 19.809922 19.039922 19.809922 19.419922 19.419922 C 19.809922 19.029922 19.809922 18.389766 19.419922 18.009766 L 18.359375 16.949219 C 18.164375 16.754219 17.906641 16.658203 17.650391 16.658203 z M 12 19 C 11.45 19 11 19.45 11 20 L 11 22 C 11 22.55 11.45 23 12 23 C 12.55 23 13 22.55 13 22 L 13 20 C 13 19.45 12.55 19 12 19 z " />
    </SvgIcon>
  );
}
