import { AreaType, FloorType, BuildingType } from "api/schema";
import { startOfToday } from "date-fns";

export interface State {
  selectedDate: Date;
  selectedBuilding?: BuildingType;
  floors: FloorType[];
  selectedFloor?: FloorType;
  meetingRooms: AreaType[];
  selectedMeetingRoom?: AreaType;
  hoursStart?: number;
  hoursEnd?: number;
  unavailableSlots: number[];
  isFavRoom: boolean;
}

export const initialState: State = {
  selectedDate: startOfToday(),
  floors: [],
  meetingRooms: [],
  unavailableSlots: [],
  isFavRoom: false,
};

export enum ActionType {
  RESET,
  SELECT_BUILDING,
  SELECT_DATE,
  SET_FLOORS,
  SELECT_FLOOR,
  SET_MEETING_ROOMS,
  SELECT_MEETING_ROOM,
  SET_HOURS_RANGE,
  SET_UNAVAILABLE_SLOTS,
  SET_IS_FAV_ROOM,
}

export interface Action {
  type: ActionType;
  payload?: unknown;
}
