import { useSharedData } from "shared/state";
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
  styled,
  Avatar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { alpha } from "@mui/material/styles";
import { BookingStatusUtil } from "shared/utils/booking-status";
import { DateUtils } from "shared/utils/date";
import { useCancelBookings } from "api/mutations/booking/cancelBookings";
import { BookingType } from "api/schema";
import LizLogo from "assets/icons/LizLogo.svg";
import DialogHeader from "./components/DialogHeader";
import DialogWrapper from "./components/DialogWrapper";

const CenteredContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexGrow: 1,
  flexDirection: "column",
  gap: 10,
});

const Card = styled("div")((props) => ({
  border: "2px solid",
  backgroundColor: alpha(props.theme.palette.primary.main, 0.2),
  borderColor: props.theme.palette.primary.main,
  padding: 10,
  borderRadius: 6,
  minWidth: 190,
}));

export function CancelBookingModal() {
  const shared = useSharedData();
  const { t } = useTranslation();
  const cancelBooking = useCancelBookings();

  const booking = shared.state.modalItem as BookingType;
  const bookingDate = `${DateUtils.formatDayAndTime(
    new Date(booking.start),
    new Date(booking.end),
  )}`;
  const [icon, title] = BookingStatusUtil.getBookingType(booking);

  const renderArea = () => {
    return (
      <>
        {booking.desk && (
          <Typography variant="subtitle2" fontSize={16}>
            {booking.area.name}
          </Typography>
        )}
        <Typography variant="body2">{booking.area.floor.floorLabel}</Typography>
        <Typography variant="body2">
          {booking.area.floor.building.name}
        </Typography>
        <Typography variant="body2">
          {booking.area.floor.building.address}
        </Typography>
      </>
    );
  };

  return (
    <DialogWrapper open>
      <DialogHeader
        icon={LizLogo}
        title={t("Profile.Edit.cancelBooking")}
        subheader={t("Home.ActiveBooking.Booking.Popup.SubTitle")}
      />
      <DialogContent>
        <CenteredContainer>
          <Typography variant="body1" fontSize={16} fontWeight={500}>
            {t("Profile.Edit.cancel.title")}
          </Typography>
          <Typography variant="body1">{bookingDate}</Typography>
          <Card>
            <Avatar variant="rounded" src={icon} />
            <Typography variant="subtitle2" fontSize={18}>
              {title}
            </Typography>
            {booking.area && renderArea()}
          </Card>
        </CenteredContainer>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              cancelBooking({
                variables: { bookingIds: [booking.id] },
              })
                .then(() => {
                  shared.dispatch(shared.Actions.setLoading(true));
                  shared.dispatch(
                    shared.Actions.notifySuccess(
                      t("Home.ActiveBookings.Booking.cancelSuccess"),
                    ),
                  );
                })
                .catch((error) => {
                  shared.dispatch(
                    shared.Actions.notifyError(
                      error?.message ||
                        t("Home.ActiveBookings.Booking.cancelFail"),
                    ),
                  );
                });
              shared.dispatch(shared.Actions.openModal());
            }}
          >
            {t("Routes.Home.Confirm")}
          </Button>
        </DialogActions>
      </DialogContent>
    </DialogWrapper>
  );
}
