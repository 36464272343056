import { CSSInterpolation } from "@mui/material/styles";
import { ComponentsProps } from "../models";

export const select: ComponentsProps = {
  MuiSelect: {
    defaultProps: {
      size: "small",
      color: "secondary",
    },
    styleOverrides: {
      select: {
        padding: "5px 16px",
      },
      outlined: (props) => {
        const styles: CSSInterpolation = {
          backgroundColor: props.theme.palette.background.default,
          border: "2px solid",
          borderColor: props.theme.palette.grey[400],
          color: props.theme.palette.text.primary,
        };
        return styles;
      },
    },
  },
};
