import { gql } from "@apollo/client";

export const areasDesks = gql`
  query areasDesks(
    $areaIds: [ID]!
    $date: DateTime
    $end: DateTime
    $start: DateTime
  ) {
    areasDesks(areaIds: $areaIds) {
      areaId
      areaName
      areaIsBookable
      areaType
      desks {
        id
        label
        coordinateX
        coordinateY
        isFree(forDatetime: $date)
        isVipForbidden(forDatetime: $date)
        isOccupied(forDatetime: $date)
        isForbidden
        blocked(start: $start, end: $end)
        numberOnFloor
        equipment {
          category {
            id
            name
          }
          id
          name
        }
        freeShare(start: $start, end: $end)
        quarterState(forDatetime: $date)
        type
      }
    }
  }
`;
