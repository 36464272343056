import { gql } from "@apollo/client";

export const startUserAttendance = gql`
  mutation startUserAttendance($startTime: DateTime!) {
    startUserAttendance(attendanceStart: { startTime: $startTime }) {
      id
      status
      startTime
      endTime
      date
      totalTimeMinutes
    }
  }
`;
