import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ErrorMessage() {
  const { t } = useTranslation();

  return (
    <Typography color="error" paddingTop={2} fontSize={14}>
      {t("Errors.Book.Unable")}
    </Typography>
  );
}
