import { createContext, useContext, Dispatch } from "react";
import { State, Action } from "./definitions";
import { Actions } from "./actions";

interface ContextInterface {
  state: State;
  dispatch: Dispatch<Action>;
  Actions: typeof Actions;
}

export const BookDeskContext = createContext({} as ContextInterface);

export function useBookDesk(): ContextInterface {
  return useContext(BookDeskContext);
}

export { initialState } from "./definitions";
export { reducer } from "./reducer";
export { Actions } from "./actions";
