import { gql } from "@apollo/client";

export const getDesks = gql`
  query getDesks($desksIds: [ID]!, $date: DateTime!) {
    desksByIds(desksIds: $desksIds) {
      id
      label
      description
      areaId
      isFree(forDatetime: $date)
      numberOnFloor
      blocked(start: $date)
      equipment {
        category {
          id
          name
        }
        id
        name
      }
      type
    }
  }
`;
