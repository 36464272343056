import { useMemo, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { StaticTimePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { setMinutes, setSeconds, formatISO } from "date-fns";
import { useSharedData } from "shared/state";
import { OrganizationHelpers } from "shared/utils/organization";
import { useEndUserAttendance } from "api/mutations/userAttendance/endUserAttendance";
import { useUserAttendances } from "api/queries/userAttendances/userAttendances";
import TimeTracking from "assets/icons/TimeTracking.svg";
import DialogHeader from "./components/DialogHeader";
import DialogWrapper from "./components/DialogWrapper";

export function ClockOutModal() {
  const { state, dispatch, Actions } = useSharedData();
  const today = new Date();
  const [selectedTime, setSelectedTime] = useState(
    setSeconds(setMinutes(today, Math.floor(today.getMinutes() / 5) * 5), 0),
  );
  const { t } = useTranslation();
  const endAttendance = useEndUserAttendance();
  const inProgress = state.userAttendances.find(
    (att) => att.status === "IN_PROGRESS",
  );

  const { refetch: refetchAttendace } = useUserAttendances((data) => {
    dispatch(Actions.setAttendances(data));
  });

  function clockOut() {
    endAttendance({
      variables: {
        attendanceId: inProgress?.id ?? "",
        endTime: formatISO(selectedTime),
      },
    })
      .then(() => {
        refetchAttendace();
        dispatch(Actions.openModal());
      })
      .catch((error) => {
        dispatch(
          Actions.notifyError(error?.message || t(["Errors.error_title"])),
        );
      });
  }

  const isDisabled = useMemo(() => {
    if (inProgress && !inProgress.attendanceBreaks.length) {
      return new Date(inProgress.startTime).getTime() > selectedTime.getTime();
    }
    if (inProgress) {
      const sortedBreaks = [...inProgress.attendanceBreaks].sort(
        (a, b) => new Date(b.endTime).getTime() - new Date(a.endTime).getTime(),
      );
      return (
        new Date(sortedBreaks[0].endTime).getTime() > selectedTime.getTime()
      );
    }
    return false;
  }, [inProgress, selectedTime]);

  return (
    <DialogWrapper open>
      <DialogHeader
        icon={TimeTracking}
        title={t("Home.Attendance.Title")}
        subheader={t("Home.ActiveBooking.Booking.Popup.SubTitle")}
      />
      <DialogContent>
        <StaticTimePicker
          label={t("Home.Attendance.Layer.Stop.Title")}
          value={selectedTime}
          onChange={(value) => {
            if (value) {
              setSelectedTime(value);
            }
          }}
          renderInput={(params) => <TextField {...params} />}
          views={["hours", "minutes"]}
          minutesStep={5}
          ampm={false}
          componentsProps={{ actionBar: { actions: [] } }}
          shouldDisableTime={(time, clockType) => {
            if (clockType === "hours") {
              return (
                time <
                  OrganizationHelpers.parseOrganizationTime(
                    (state.myOrganization?.workingHoursStart ?? "").toString(),
                  ) ||
                time >
                  OrganizationHelpers.parseOrganizationTime(
                    (state.myOrganization?.workingHoursEnd ?? "").toString(),
                  ) ||
                time > today.getHours()
              );
            }
            if (
              clockType === "minutes" &&
              selectedTime.getHours() === today.getHours()
            ) {
              return time > today.getMinutes();
            }
            return false;
          }}
        />
        {isDisabled && (
          <Typography color="error" paddingTop={2} fontSize={14}>
            {t("Home.Attendance.Layer.ErrorTimeOverlapping")}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isDisabled}
          onClick={() => clockOut()}
        >
          {t("Home.Attendance.Button.Save")}
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
}
