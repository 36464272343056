import { gql } from "@apollo/client";

export const getUnavailableTimePeriods = gql`
  query getUnavailableTimePeriods(
    $areaId: ID
    $date: Date!
    $deskId: ID
    $homeOffice: Boolean
    $parkingId: ID
    $travelDay: Boolean
  ) {
    unavailableTimePeriods(
      areaId: $areaId
      date: $date
      deskId: $deskId
      homeOffice: $homeOffice
      parkingId: $parkingId
      travelDay: $travelDay
    ) {
      start
      end
      userId
      unavailableType
    }
  }
`;
