import { createTheme } from "@mui/material/styles";
import { button } from "./Button";
import { datePicker } from "./DatePicker";
import { select } from "./Select";
import { textField } from "./TextField";
import { card } from "./Card";
import { alert } from "./Alert";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#081275",
      light: "#CED0E3",
    },
    success: {
      main: "#237B4B",
      light: "#E7F2DA",
    },
    error: {
      main: "#FF4387",
      light: "#FCF4F6",
    },
    warning: {
      main: "#FF4387",
    },
    text: {
      primary: "#242424",
    },
    background: {
      default: "#F5F5F5",
    },
  },
  components: {
    ...button,
    ...datePicker,
    ...select,
    ...textField,
    ...card,
    ...alert,
  },
});
