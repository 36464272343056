import { DialogContent, Typography, styled } from "@mui/material";
import { useSharedData, ModalType } from "shared/state";
import { useTranslation } from "react-i18next";
import LizLogo from "assets/icons/LizLogo.svg";
import BookDesk from "assets/icons/BookDesk.svg";
import BookMeetingRoom from "assets/icons/BookMeetingRoom.svg";
import BookTelephoneBox from "assets/icons/BookTelephoneBox.svg";
import BookParkingSpot from "assets/icons/BookParkingSpot.svg";
import BookFreeSpace from "assets/icons/BookFreeSpace.svg";
import SickDay from "assets/icons/SickDay.svg";
import BusinessTravel from "assets/icons/BusinessTravel.svg";
import Vacation from "assets/icons/Vacation.svg";
import WorkFromHome from "assets/icons/WorkFromHome.svg";
import ActionCard from "shared/components/cards/ActionCard";
import DialogHeader from "./components/DialogHeader";
import DialogWrapper from "./components/DialogWrapper";
import DatePicker from "./components/DatePicker";

const ContentConteainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(170px, 1fr))",
  gap: 20,
});

export function SelectBookingModal() {
  const { state, dispatch, Actions } = useSharedData();
  const { t } = useTranslation();

  const featureFlags = state.myOrganization?.enabledFeatures;

  return (
    <DialogWrapper open={state.modalOpen === ModalType.BOOKING}>
      <DialogHeader
        icon={LizLogo}
        title={t("Routes.tabs.book")}
        subheader={t("Home.ActiveBooking.Booking.Popup.SubTitle")}
      />
      <DialogContent>
        <DatePicker
          initialValue={state.modalInitialDate}
          onChange={(value) => dispatch(Actions.setModalInitialDate(value))}
        />
        <Typography variant="caption" display="block" gutterBottom>
          {t("Home.Booking.Select")}
        </Typography>
        <ContentConteainer>
          <ActionCard
            image={BookDesk}
            title={t("Routes.Home.Book.BookDesk")}
            onSelect={() => {
              dispatch(Actions.openModal(ModalType.BOOK_DESK));
            }}
          />
          <ActionCard
            image={BookMeetingRoom}
            title={t("Routes.Home.Book.MeetingRoom")}
            onSelect={() => {
              dispatch(Actions.openModal(ModalType.BOOK_MEETING_ROOM));
            }}
          />
          <ActionCard
            image={BookFreeSpace}
            title={t("Routes.Home.Book.FreeSpace")}
            onSelect={() => {
              dispatch(Actions.openModal(ModalType.BOOK_FREE_SPACE));
            }}
          />
          <ActionCard
            image={BookTelephoneBox}
            title={t("Routes.Home.Book.TelefonBox")}
            onSelect={() => {
              dispatch(Actions.openModal(ModalType.BOOK_TELEPHONE_BOX));
            }}
          />
          {featureFlags?.includes("parkings_enabled") && (
            <ActionCard
              image={BookParkingSpot}
              title={t("Routes.Home.Book.ParkingSpot")}
              onSelect={() => {
                dispatch(Actions.openModal(ModalType.BOOK_PARKING_SPOT));
              }}
            />
          )}
          {featureFlags?.includes("vacation") && (
            <ActionCard
              image={Vacation}
              title={t("Routes.Home.Book.Vacation")}
              onSelect={() => {
                dispatch(Actions.openModal(ModalType.VACATION));
              }}
            />
          )}
          {featureFlags?.includes("travel_day") && (
            <ActionCard
              image={BusinessTravel}
              title={t("Routes.Home.Book.TravelDay")}
              onSelect={() => {
                dispatch(Actions.openModal(ModalType.BUSINESS_TRIP));
              }}
            />
          )}
          {featureFlags?.includes("home_office") && (
            <ActionCard
              image={WorkFromHome}
              title={t("Routes.Home.Book.HomeOffice")}
              onSelect={() => {
                dispatch(Actions.openModal(ModalType.WORK_FROM_HOME));
              }}
            />
          )}
          {featureFlags?.includes("sick_day") && (
            <ActionCard
              image={SickDay}
              title={t("Routes.Home.Book.Sickday")}
              onSelect={() => {
                dispatch(Actions.openModal(ModalType.SICK_DAY));
              }}
            />
          )}
        </ContentConteainer>
      </DialogContent>
    </DialogWrapper>
  );
}
