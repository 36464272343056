import { gql } from "@apollo/client";

export const startUserAttendanceBreak = gql`
  mutation startUserAttendanceBreak(
    $attendanceId: ID!
    $breakStartTime: DateTime!
  ) {
    startUserAttendanceBreak(
      breakStart: {
        attendanceId: $attendanceId
        breakStartTime: $breakStartTime
      }
    ) {
      id
      status
      startTime
      endTime
      date
      totalTimeMinutes
    }
  }
`;
