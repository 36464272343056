import { gql } from "@apollo/client";

export const getBuildingStructureForMeetingRooms = gql`
  query getBuildingStructureForMeetingRooms(
    $areaType: AreaTypeEnum
    $buildingId: ID!
    $date: Date!
    $start: DateTime
    $end: DateTime
  ) {
    building(buildingId: $buildingId) {
      id
      name
      image
      address
      numberOfMeetingRooms
      floors {
        id
        floorLabel
        floorNumber
        floorType
        areas(areaType: $areaType) {
          id
          areaType
          image
          name
          isBookable
          isMeetingRoomFree(start: $start, end: $end)
          meetingRoomTimeStats(date: $date) {
            booked
            total
          }
          equipment {
            category {
              id
              name
            }
            id
            name
          }
        }
        meetingRoomTimeStats(date: $date) {
          booked
          total
        }
      }
    }
  }
`;
