import { gql } from "@apollo/client";

export const userAttendances = gql`
  query userAttendances($startDate: Date, $endDate: Date) {
    userAttendances(startDate: $startDate, endDate: $endDate) {
      id
      status
      startTime
      endTime
      date
      totalTimeMinutes
      attendanceBreaks {
        id
        attendanceId
        status
        startTime
        endTime
        totalTimeMinutes
        date
      }
    }
    userAttendanceBreaks(startDate: $startDate, endDate: $endDate) {
      id
      attendanceId
      startTime
      endTime
      status
      totalTimeMinutes
      date
    }
  }
`;
