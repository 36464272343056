import { useMutation } from "@apollo/client";
import { BookingType, CreateBookingTypeInput } from "api/schema";
import { createBooking } from "gql/mutations/booking/createBooking";

interface GqlData {
  createBooking: BookingType;
}

interface GqlVars {
  booking: CreateBookingTypeInput;
}

export function useCreateBooking() {
  return useMutation<GqlData, GqlVars>(createBooking)[0];
}
