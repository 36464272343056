import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { AreaType, AreaDesks, DeskType } from "api/schema";
import { areasDesks } from "gql/queries/areas/areasDesks";

interface GqlData {
  areasDesks: AreaDesks[];
}

interface GqlVars {
  areaIds: string[];
  date: string;
  start: string;
  end: string;
}

type OnSettled = (data: DeskType[]) => void;

interface QueryParams {
  date: Date;
  hoursStart: Date;
  hoursEnd: Date;
  areas?: AreaType[];
}

export function useAreaDesks(onSettled?: OnSettled, params?: QueryParams) {
  const today = new Date();
  const areaIds = (params?.areas || [])
    .filter((area) => area.areaType === "room")
    .map((area) => area.id);

  const q = useQuery<GqlData, GqlVars>(areasDesks, {
    variables: {
      areaIds,
      date: params?.date.toISOString() ?? today.toISOString(),
      start: params?.hoursStart.toISOString() ?? "",
      end: params?.hoursEnd.toISOString() ?? "",
    },
    skip: !(areaIds.length > 0),
  });

  useEffect(() => {
    if (q.data?.areasDesks) {
      const flatData = [...q.data.areasDesks]
        .flatMap(
          (area) =>
            area.desks.map((desk) => {
              return { ...desk, areaId: area.areaId };
            }) || [],
        )
        .filter((area) => !area.isForbidden && !area.isVipForbidden);
      onSettled?.(flatData);
    }
  }, [q.data]);

  return q;
}
