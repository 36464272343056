import { gql } from "@apollo/client";

export const areasParkings = gql`
  query areasParkings($areaIds: [ID]!, $date: DateTime) {
    areasParkings(areaIds: $areaIds) {
      areaId
      areaName
      areaIsBookable
      areaType
      parkings {
        blocked(start: $date)
        combinedType
        coordinateX
        coordinateY
        id
        isForbidden
        isFree(forDatetime: $date)
        isOccupied(forDatetime: $date)
        isVipForbidden(forDatetime: $date)
        label
        name
        numberOnFloor
        quarterState(forDatetime: $date)
      }
    }
  }
`;
