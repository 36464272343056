import { useMutation } from "@apollo/client";
import { BookingType, CreateSickDayBookingInput } from "api/schema";
import { createBooking } from "gql/mutations/booking/createSickDayBooking";

interface GqlData {
  createSickDayBooking: BookingType[];
}

interface GqlVars {
  booking: CreateSickDayBookingInput;
}

export function useCreateSickDayBooking() {
  return useMutation<GqlData, GqlVars>(createBooking)[0];
}
