import { useState, useMemo } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import i18n, { SupportedLanguage } from "i18n";
import { useTranslation } from "react-i18next";
import { useSharedData } from "shared/state";
import { useProblemSubjects } from "api/queries/userProblemSubjects";
import { useReportAProblem } from "api/mutations/reportProblem";
import { BookingType, UserProblemSubjectType } from "api/schema";
import ReportProblem from "assets/icons/ReportProblem.svg";
import DialogHeader from "./components/DialogHeader";
import DialogWrapper from "./components/DialogWrapper";

export function ReportAProblemModal() {
  const { state, dispatch, Actions } = useSharedData();
  const { t } = useTranslation();

  const [problemSubject, setProblemSubject] =
    useState<UserProblemSubjectType>();
  const [details, setDetails] = useState<string>();

  const problemSubjects = useProblemSubjects();
  const reportAProblem = useReportAProblem();

  const language = i18n.language as SupportedLanguage;
  const location = useMemo<string>(() => {
    if (state.modalItem) {
      const booking = state.modalItem as BookingType;
      return [
        booking.area.floor.building.name,
        booking.area.floor.floorLabel,
        booking.area.name,
      ].join(", ");
    }
    return "";
  }, [state.modalItem]);

  return (
    <DialogWrapper open>
      <DialogHeader
        icon={ReportProblem}
        title={t("Home.ActiveBookings.Booking.Problem.titleLong")}
        subheader={t("Home.ActiveBooking.Booking.Popup.SubTitle")}
      />
      <DialogContent>
        <Typography variant="caption" display="block" gutterBottom>
          {t("Home.ActiveBookings.Booking.Problem.located")}
        </Typography>
        <TextField
          fullWidth
          InputProps={{ readOnly: true }}
          defaultValue={location}
        />

        <Typography variant="caption" display="block" gutterBottom>
          {t("Home.ActiveBookings.Booking.Problem.Subject")}
        </Typography>
        <FormControl disabled={problemSubjects.loading}>
          <Select
            value={problemSubject?.id ?? ""}
            onChange={(ev) => {
              setProblemSubject(
                problemSubjects.data?.userProblemSubjects.find(
                  (s) => s.id === ev.target.value,
                ),
              );
            }}
          >
            <MenuItem disabled value="">
              {t("Home.ActiveBookings.Booking.Problem.Subject.placeholder")}
            </MenuItem>
            {problemSubjects.data?.userProblemSubjects?.map((subject) => (
              <MenuItem key={subject.id} value={subject.id}>
                {subject[language]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography variant="caption" display="block" gutterBottom>
          {t("Home.ActiveBookings.Booking.Problem.details")}
        </Typography>
        <TextField
          multiline
          rows={4}
          fullWidth
          value={details}
          onChange={(event) => {
            setDetails(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!(details?.length && problemSubject?.en)}
          onClick={() => {
            const booking = state.modalItem as BookingType;
            reportAProblem({
              variables: {
                problem: {
                  bookingId: booking.id,
                  subjectId: problemSubject?.id ?? "",
                  subjectText: problemSubject?.en ?? "",
                  body: details ?? "",
                },
              },
            })
              .then(() => {
                dispatch(
                  Actions.notifySuccess(
                    t("Home.ActiveBookings.Booking.Problem.reportSend"),
                  ),
                );
              })
              .catch((error) => {
                dispatch(
                  Actions.notifyError(
                    error?.message ||
                      t("Home.ActiveBookings.Booking.Problem.reportFail"),
                  ),
                );
              });
            dispatch(Actions.openModal());
          }}
        >
          {t("Home.ActiveBookings.Booking.Problem.send")}
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
}
