import { useState, useEffect } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import SickDay from "assets/icons/SickDay.svg";
import { useTranslation } from "react-i18next";
import { useSharedData } from "shared/state";
import { filterActiveBookings } from "shared/utils/filter-booking";
import { useMyActiveBookings } from "api/queries/bookings/myActiveBookings";
import { useCreateSickDayBooking } from "api/mutations/booking/createSickDayBooking";
import { DateUtils } from "shared/utils/date";
import { OrganizationHelpers } from "shared/utils/organization";
import DialogHeader from "./components/DialogHeader";
import DialogWrapper from "./components/DialogWrapper";
import DatePicker from "./components/DatePicker";

export function SickDayModal() {
  const { state, dispatch, Actions } = useSharedData();
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<Date>(
    DateUtils.setFullUTCHour(
      state.modalInitialDate,
      OrganizationHelpers.getWorkingHoursStart(
        state.myOrganization,
        state.modalInitialDate,
      ).getHours(),
    ),
  );
  const [endDate, setEndDate] = useState<Date>(
    DateUtils.setFullUTCHour(
      state.modalInitialDate,
      OrganizationHelpers.getWorkingHoursEnd(
        state.myOrganization,
        state.modalInitialDate,
      ).getHours(),
    ),
  );

  const { refetch: refetchActiveBookings } = useMyActiveBookings(
    (data) => {
      const filteredBySickDay = filterActiveBookings(data, "sick-day");
      dispatch(Actions.setMyBookedSlots(filteredBySickDay));
    },
    {
      start: startDate,
      end: endDate,
    },
  );

  useEffect(() => {
    refetchActiveBookings().then(() => {
      dispatch(Actions.setLoading(false));
    });
  }, [startDate, endDate]);

  const createBooking = useCreateSickDayBooking();

  return (
    <DialogWrapper open>
      <DialogHeader
        icon={SickDay}
        title={t("Home.ActiveBookings.Booking.title.sickday")}
        subheader={t("Home.ActiveBooking.Booking.Popup.SubTitle")}
      />
      <DialogContent>
        <DatePicker
          caption={t("Home.Book.Sickday.Today") as string}
          initialValue={startDate}
          onChange={(value) => {
            const formattedStart = DateUtils.setFullUTCHour(
              value,
              OrganizationHelpers.getWorkingHoursStart(
                state.myOrganization,
                value,
              ).getHours(),
            );
            const formattedEnd = DateUtils.setFullUTCHour(
              value,
              OrganizationHelpers.getWorkingHoursEnd(
                state.myOrganization,
                value,
              ).getHours(),
            );
            setEndDate(formattedEnd);
            setStartDate(formattedStart);
          }}
        />
        {!!state.myBookedSlots?.length && (
          <Typography color="error" paddingTop={2} fontSize={14}>
            {t("Home.Book.Sickday.Disabled")}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!!state.myBookedSlots?.length}
          onClick={() => {
            createBooking({
              variables: {
                booking: {
                  start: startDate,
                  end: endDate,
                  comment: "",
                },
              },
            })
              .then(() => {
                dispatch(Actions.setLoading(true));
                dispatch(
                  Actions.notifySuccess(t("Home.ActiveBooking.Booking.title")),
                );
              })
              .catch((error) => {
                dispatch(
                  Actions.notifyError(
                    error?.message || t("Errors.error_title"),
                  ),
                );
              });
            dispatch(Actions.openModal());
          }}
        >
          {t("Routes.Home.Book.Confirm")}
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
}
