import { createContext, useContext, Dispatch } from "react";
import { State, Action } from "./definitions";
import { Actions } from "./actions";

interface ContextInterface {
  state: State;
  dispatch: Dispatch<Action>;
  Actions: typeof Actions;
}

export const WorkFromHomeContext = createContext({} as ContextInterface);

export function useBusinessTrip(): ContextInterface {
  return useContext(WorkFromHomeContext);
}

export { initialState } from "./definitions";
export { reducer } from "./reducer";
export { Actions } from "./actions";
