import { ReactElement } from "react";
import {
  Card as MuiCard,
  CardHeader,
  CardContent as MuiCardContent,
  Typography,
  Avatar,
} from "@mui/material";
import { CSSInterpolation, styled, useTheme } from "@mui/material/styles";

const Card = styled(MuiCard, {
  shouldForwardProp(propName) {
    return propName !== "isActive";
  },
})<{ isActive?: boolean }>((props) => {
  const { palette } = props.theme;
  const isDark = props.theme.palette.mode === "dark";
  const isActive = Boolean(props.isActive);

  const styles: CSSInterpolation = {
    border: `1px solid`,
    borderColor: palette.grey[isDark ? 600 : 300],
    transition: "background-color 500ms, border-color 300ms",
    cursor: "pointer",
  };

  if (isActive) {
    if (isDark) {
      Object.assign(styles, {
        borderColor: palette.success.light,
      } as CSSInterpolation);
    } else {
      Object.assign(styles, {
        borderColor: palette.success.main,
        backgroundColor: palette.success.light,
      } as CSSInterpolation);
    }
  } else {
    Object.assign(styles, {
      ":hover": {
        borderColor: palette.success.main,
        backgroundColor: isDark ? "transparent" : palette.background.default,
      },
    } as CSSInterpolation);
  }

  return styles;
});

const CardContent = styled(MuiCardContent)({
  paddingTop: 0,
});

interface ToggleCardProps {
  image?: ReactElement;
  title?: string;
  subtitle?: string;
  isActive?: boolean;
  onToggle?: () => void;
}

export function ToggleCard({
  image,
  title,
  subtitle,
  isActive,
  onToggle,
}: ToggleCardProps) {
  const theme = useTheme();

  return (
    <Card isActive={isActive} onClick={() => onToggle?.()}>
      <CardHeader
        avatar={
          <Avatar
            variant="rounded"
            sx={{
              backgroundColor: isActive
                ? theme.palette.success.light
                : theme.palette.background.default,
              color: isActive
                ? theme.palette.success.main
                : theme.palette.text.primary,
            }}
          >
            {image}
          </Avatar>
        }
      />
      <CardContent>
        <Typography variant="body2">{title}</Typography>
        <Typography fontSize={12}>{subtitle}</Typography>
      </CardContent>
    </Card>
  );
}
