import { createContext, useContext, Dispatch } from "react";
import { State, Action } from "./definitions";
import { Actions } from "./actions";

interface ContextInterface {
  state: State;
  dispatch: Dispatch<Action>;
  Actions: typeof Actions;
}

export const SharedContext = createContext({} as ContextInterface);

export function useSharedData(): ContextInterface {
  return useContext(SharedContext);
}

export { initialState, ModalType } from "./definitions";
export { reducer } from "./reducer";
export { Actions } from "./actions";
