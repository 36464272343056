import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSharedData, ModalType } from "shared/state";
import { useMyOrganization } from "api/queries/myOrganization";
import { useGetMe } from "api/queries/getMe";
import { useBuildingsInfo } from "api/queries/buildingsInfo";
import {
  BookDeskModal,
  BookMeetingRoomModal,
  BookParkingSpotModal,
  BookFreeSpaceModal,
  BookTelephoneBoxModal,
  BusinessTripModal,
  ReportAProblemModal,
  SelectBookingModal,
  VacationModal,
  WorkFromHomeModal,
  FavouritesModal,
  SickDayModal,
  CancelBookingModal,
  BookingInfoModal,
  TimeTrackingModal,
  ClockOutModal,
} from "shared/components/modals";
import { Snackbar, Alert } from "@mui/material";
import { OrganizationHelpers } from "shared/utils/organization";

export function SharedStateRoute() {
  const { state, dispatch, Actions } = useSharedData();

  const { refetch: refetchMe } = useGetMe((data) => {
    dispatch(Actions.setUser(data));
    dispatch(Actions.setLoading(false));
  });

  useMyOrganization((data) => {
    dispatch(Actions.setOrganization(data));
    if (data.translations.length) {
      OrganizationHelpers.getCustomTranslation(data.translations);
    }
  });

  useBuildingsInfo((data) => {
    dispatch(Actions.setBuildings(data));
  });

  useEffect(() => {
    if (state.isLoading) {
      refetchMe().then(() => {
        dispatch(Actions.setLoading(false));
      });
    }
  }, [state.isLoading]);

  return (
    <>
      <Outlet />
      <SelectBookingModal />
      {state.modalOpen === ModalType.BOOK_DESK && <BookDeskModal />}
      {state.modalOpen === ModalType.BOOK_MEETING_ROOM && (
        <BookMeetingRoomModal />
      )}
      {state.modalOpen === ModalType.BOOK_PARKING_SPOT && (
        <BookParkingSpotModal />
      )}
      {state.modalOpen === ModalType.BOOK_FREE_SPACE && <BookFreeSpaceModal />}
      {state.modalOpen === ModalType.BOOK_TELEPHONE_BOX && (
        <BookTelephoneBoxModal />
      )}
      {state.modalOpen === ModalType.BUSINESS_TRIP && <BusinessTripModal />}
      {state.modalOpen === ModalType.REPORT_PROBLEM && <ReportAProblemModal />}
      {state.modalOpen === ModalType.VACATION && <VacationModal />}
      {state.modalOpen === ModalType.WORK_FROM_HOME && <WorkFromHomeModal />}
      {state.modalOpen === ModalType.SICK_DAY && <SickDayModal />}
      {state.modalOpen === ModalType.FAVOURITES && <FavouritesModal />}
      {state.modalOpen === ModalType.CANCEL_BOOKING && <CancelBookingModal />}
      {state.modalOpen === ModalType.BOOKING_INFO && <BookingInfoModal />}
      {state.modalOpen === ModalType.TIME_TRACKING && <TimeTrackingModal />}
      {state.modalOpen === ModalType.CLOCK_OUT && <ClockOutModal />}

      <Snackbar
        open={state.notification?.isOpen}
        onClose={() => dispatch(Actions.notifyClose())}
        autoHideDuration={state.notification?.color === "error" ? null : 5000}
      >
        <Alert
          onClose={() => dispatch(Actions.notifyClose())}
          severity={state.notification?.color}
          sx={{ width: "100%" }}
        >
          {state.notification?.body}
        </Alert>
      </Snackbar>
    </>
  );
}
