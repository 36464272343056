import { gql } from "@apollo/client";

export const createBooking = gql`
  mutation createBooking($booking: CreateBookingTypeInput!) {
    createBooking(booking: $booking) {
      id
      start
      end
      status
      checkedIn
      checkedOut
      desk {
        areaId
        id
        type
        label
        numberOnFloor
      }
      area {
        id
        name
        areaType
        floor {
          floorLabel
          floorNumber
          floorType
          building {
            name
            address
          }
        }
      }
      comment
    }
  }
`;
