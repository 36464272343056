import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { CurrentUserType, UserProfileType } from "api/schema";
import { getMe } from "gql/queries/me/me";

export interface MyInfo extends CurrentUserType {
  userprofile: UserProfileType;
}

interface GqlData {
  me: MyInfo;
}

interface GqlVars {}

type OnSettled = (data: MyInfo) => void;

interface QueryParams {
  skip?: boolean;
}

export function useGetMe(onSettled?: OnSettled, params?: QueryParams) {
  const q = useQuery<GqlData, GqlVars>(getMe, {
    skip: Boolean(params?.skip),
  });

  useEffect(() => {
    if (q.data?.me) {
      onSettled?.(q.data.me);
    }
  }, [q.data]);

  return q;
}
