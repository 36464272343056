import { PropsWithChildren } from "react";
import { Dialog as MuiDialog } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSharedData } from "shared/state";

const Dialog = styled(MuiDialog)((props) => ({
  " .MuiDialog-paper": {
    width: "600px",
    backgroundImage: "none",
  },
  " .MuiInputBase-root": {
    backgroundColor: props.theme.palette.background.default,
  },
  " .MuiFormControl-root": {
    width: "100%",
  },
  " .MuiDialogActions-root": {
    padding: 15,
  },
  " .MuiTypography-caption": {
    marginTop: 10,
  },
  " .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

interface DialogWrapperProps extends PropsWithChildren {
  open: boolean;
}

export default function DialogWrapper({ open, children }: DialogWrapperProps) {
  const { dispatch, Actions } = useSharedData();

  return (
    <Dialog onClose={() => dispatch(Actions.openModal())} open={open}>
      {children}
    </Dialog>
  );
}
