import {
  OrganizationType,
  DayEnumType,
  UnavailableTimePeriodsType,
  TranslationType,
  AttendanceStatus,
  UserAttendanceType,
} from "api/schema";
import { addDays, startOfWeek, isBefore, startOfToday } from "date-fns";
import i18n from "i18n";

function getWorkingHoursRange(
  timeType: keyof OrganizationType,
  org?: OrganizationType,
  chosenDate?: Date,
): Date {
  const safeOrgTime = org?.[timeType]?.toString() || "00:00:00";
  const hour = parseInt(safeOrgTime?.split(":")?.at(0) ?? "0", 10);
  const date = new Date(chosenDate ?? new Date());
  date.setHours(hour, 0, 0, 0);
  return date;
}

export namespace OrganizationHelpers {
  export function getWorkingHoursStart(
    org?: OrganizationType,
    chosenDate?: Date,
  ): Date {
    return getWorkingHoursRange("workingHoursStart", org, chosenDate);
  }

  export function getWorkingHoursEnd(
    org?: OrganizationType,
    chosenDate?: Date,
  ): Date {
    return getWorkingHoursRange("workingHoursEnd", org, chosenDate);
  }

  export function getMeetingRoomHoursStart(
    org?: OrganizationType,
    chosenDate?: Date,
  ): Date {
    return getWorkingHoursRange("meetingRoomHoursStart", org, chosenDate);
  }

  export function getMeetingRoomHoursEnd(
    org?: OrganizationType,
    chosenDate?: Date,
  ): Date {
    return getWorkingHoursRange("meetingRoomHoursEnd", org, chosenDate);
  }

  export function parseOrganizationTime(date: string): number {
    return parseInt(date.split(":")[0] ?? "0", 10);
  }

  export const OrgDays: DayEnumType[] = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  export function orgWorkingDays(org?: OrganizationType): number[] {
    const dayIndexStart = OrgDays.findIndex(
      (value) => value === (org?.workingDaysStart ?? "monday"),
    );
    const dayIndexEnd = OrgDays.findIndex(
      (value) => value === (org?.workingDaysEnd ?? "friday"),
    );
    const workdaysIndices = [];
    let index = dayIndexStart;
    while (index !== dayIndexEnd) {
      workdaysIndices.push(index);
      index = (index + 1) % 7;
    }
    workdaysIndices.push(index);
    return workdaysIndices;
  }

  export function orgWeekendDays(org?: OrganizationType): number[] {
    const workdaysIndices = new Set(orgWorkingDays(org));
    return [0, 1, 2, 3, 4, 5, 6].filter((day) => !workdaysIndices.has(day));
  }

  export function isOrgWeekendDay(date: Date, org?: OrganizationType): boolean {
    return orgWeekendDays(org).some((day) => date.getDay() === day);
  }

  export function orgStartOfWeek(
    org?: OrganizationType,
    today = new Date(),
  ): Date {
    const workingDays = orgWorkingDays(org);
    const daysOffCount = 7 - workingDays.length;
    return startOfWeek(
      isOrgWeekendDay(today, org) ? addDays(today, daysOffCount) : today,
      {
        weekStartsOn: workingDays[0] as any,
      },
    );
  }

  export function orgWholeWeek(
    org?: OrganizationType,
    today = new Date(),
  ): Date[] {
    const workingDays = orgWorkingDays(org);
    const beginOfWeek = orgStartOfWeek(org, today);
    return workingDays.map((day, i) => addDays(beginOfWeek, i));
  }

  export function isUnavailableDay(
    org?: OrganizationType,
    date = new Date(),
  ): boolean {
    return isOrgWeekendDay(date, org) || isBefore(date, startOfToday());
  }

  export function getUnavailableWorkingHours(
    org?: OrganizationType,
    date = new Date(),
    arr: number[] = [],
  ): number[] {
    const hoursStart = getWorkingHoursStart(org, date).getHours();
    const hoursEnd = getWorkingHoursEnd(org, date).getHours();
    const hours = new Set<number>(arr);

    for (let i = 0; i < 24; i++) {
      if (i < hoursStart || i > hoursEnd) {
        hours.add(i);
      }
    }
    return Array.from(hours).sort((a, b) => a - b);
  }

  export function getUnavailableMeetingHours(
    org?: OrganizationType,
    date = new Date(),
    arr: number[] = [],
  ): number[] {
    const hoursStart = getMeetingRoomHoursStart(org, date).getHours();
    const hoursEnd = getMeetingRoomHoursEnd(org, date).getHours();
    const hours = new Set<number>(arr);

    for (let i = 0; i < 24; i++) {
      if (i < hoursStart || i > hoursEnd) {
        hours.add(i);
      }
    }
    return Array.from(hours).sort((a, b) => a - b);
  }

  export function getUnavailableTimePeriods(
    timePeriods: UnavailableTimePeriodsType[],
  ): number[] {
    const unavailableHours: number[] = [];
    timePeriods.forEach((range) => {
      const start = new Date(range.start);
      const end = new Date(range.end);
      for (let i = start.getUTCHours(); i < end.getUTCHours(); i++) {
        unavailableHours.push(i);
      }
    });
    return unavailableHours;
  }

  export function getMidDay(org?: OrganizationType): number {
    // Function is made to handle properly different data types
    // Eg. midDayHour is on some envs a number, and string elsewhere
    // 13 vs hour_13
    const midHour = parseInt(
      (org?.midDayHour.toString() ?? "").match(/\d+/g)?.[0] ?? "",
      10,
    );
    return Number.isNaN(midHour) ? -1 : midHour;
  }

  export function getAttendanceStatus(
    arr: UserAttendanceType[],
  ): AttendanceStatus {
    if (arr.some((att) => att.status === "ON_BREAK")) return "ON_BREAK";
    if (arr.some((att) => att.status === "IN_PROGRESS")) return "IN_PROGRESS";
    return "OPEN";
  }

  export function getCustomTranslation(data: TranslationType[]): void {
    const customLang = data.reduce((acc, el) => {
      return {
        ...acc,
        [el.key]: el.value,
      };
    }, {});
    i18n.addResourceBundle(i18n.language, "translation", customLang);
  }
}
