import { useMutation } from "@apollo/client";
import { BookingType } from "api/schema";
import { bookMeetingRoom } from "gql/mutations/booking/createMeetingRoom";

interface GqlData {
  createBooking: BookingType;
}

interface GqlVars {
  areaId: string;
  start: Date;
  end: Date;
}

export function useCreateMeetingRoom() {
  return useMutation<GqlData, GqlVars>(bookMeetingRoom)[0];
}
