import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { HashRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import "./i18n";
import "./index.css";
import "bootstrap/dist/css/bootstrap-grid.css";
import "bootstrap/dist/css/bootstrap-utilities.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

createRoot(document.getElementById("root") as any).render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div className="centered-container">
          <div className="lds-dual-ring" />
        </div>
      }
    >
      <HashRouter>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ""}
          cacheLocation="localstorage"
          skipRedirectCallback
          useRefreshTokens
          authorizationParams={{
            redirect_uri: window.location.origin,
            scope: "openid profile email offline_access",
            audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
          }}
          useCookiesForTransactions={true} // eslint-disable-line react/jsx-boolean-value
        >
          <App />
        </Auth0Provider>
      </HashRouter>
    </Suspense>
  </React.StrictMode>,
);
