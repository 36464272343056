import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { getMyActiveBookings } from "gql/queries/bookings/myActiveBookings";
import { DateUtils } from "shared/utils/date";
import { BookingType } from "api/schema";

interface GqlData {
  actives: BookingType[];
  checkedIn: BookingType[];
}

interface GqlVars {
  end: string;
  start: string;
}

interface QueryParams {
  end: Date;
  start: Date;
  skip?: boolean;
}

type OnSettled = (data: GqlData) => void;

export function useMyActiveBookings(onSettled: OnSettled, params: QueryParams) {
  const q = useQuery<GqlData, GqlVars>(getMyActiveBookings, {
    variables: {
      start: DateUtils.setFullUTCHour(params.start, 0).toISOString(),
      end: DateUtils.setFullUTCHour(params.end, 23, 59, 59).toISOString(),
    },
    skip: Boolean(params?.skip),
  });

  useEffect(() => {
    if (q.data) {
      onSettled?.(q.data);
    }
  }, [q.data]);

  return q;
}
