import { gql } from "@apollo/client";

export const createBooking = gql`
  mutation createBooking($booking: CreateVacationBookingTypeInput!) {
    createVacationBooking(booking: $booking) {
      id
      start
      end
      vacation
      comment
    }
  }
`;
