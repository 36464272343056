import { useTranslation } from "react-i18next";
import { Button, Typography, Box } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

interface LoginLayoutProps {
  onClick: () => void;
}

export default function LoginLayout({ onClick }: LoginLayoutProps) {
  const { t } = useTranslation();

  return (
    <Box textAlign="center" maxWidth={500}>
      <Button
        color="primary"
        onClick={onClick}
        startIcon={<AccountCircleIcon />}
      >
        {t(["Routes.tabs.login"])}
      </Button>

      <Typography mt={7} px={1}>
        {t(["login.introText"])}
      </Typography>

      <Typography mt={3} px={1} variant="body1">
        {t(["login.createAccount"], {
          email: "info@liz.solutions",
        })}
      </Typography>
    </Box>
  );
}
