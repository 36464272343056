import { gql } from "@apollo/client";

export const bookFreeSpace = gql`
  mutation bookFreeSpace(
    $areaId: ID!
    $start: DateTime!
    $end: DateTime!
    $comment: String
  ) {
    createBooking(
      booking: { areaId: $areaId, start: $start, end: $end, comment: $comment }
    ) {
      start
      end
      area {
        areaType
        name
        floor {
          floorLabel
          floorNumber
          floorType
          building {
            name
            address
          }
        }
      }
      comment
    }
  }
`;
