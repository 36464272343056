// https://mui.com/x/react-date-pickers/getting-started/
// import { CSSInterpolation } from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { ComponentsProps } from "../models";

export const datePicker: ComponentsProps = {
  MuiDatePicker: {
    defaultProps: {},
    styleOverrides: {
      root: {
        // const styles: CSSInterpolation = {
        //   backgroundColor: props.theme.palette.grey[700],
        //   border: "1px solid",
        //   borderColor: props.theme.palette.grey[200],
        //   color: props.theme.palette.text.primary,
        // };
        // return styles;
      },
    },
  },
};
