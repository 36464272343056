import { AreaType, FloorType, BuildingType, ParkingType } from "api/schema";
import { TimeSlot } from "shared/utils/date";
import { State, initialState, Action, ActionType } from "./definitions";

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.RESET: {
      return {
        ...initialState,
      };
    }
    case ActionType.SELECT_DATE: {
      return {
        ...state,
        selectedDate: action.payload as Date,
      };
    }
    case ActionType.SELECT_BUILDING: {
      return {
        ...state,
        selectedBuilding: action.payload as BuildingType,
        floors: [],
        selectedFloor: undefined,
        areas: [],
        selectedArea: undefined,
        parkingSpots: [],
        selectedParkingSpot: undefined,
      };
    }
    case ActionType.SET_FLOORS: {
      const floors = action.payload as FloorType[];
      const selectedFloor = floors.find(
        (f) => f.id === state.selectedFloor?.id,
      );
      return {
        ...state,
        floors,
        selectedFloor,
        areas: [],
        selectedArea: undefined,
        parkingSpots: [],
        selectedParkingSpot: undefined,
      };
    }
    case ActionType.SELECT_FLOOR: {
      return {
        ...state,
        selectedFloor: action.payload as FloorType,
        areas: [],
        selectedArea: undefined,
        parkingSpots: [],
        selectedParkingSpot: undefined,
      };
    }
    case ActionType.SET_AREAS: {
      const areas = action.payload as AreaType[];
      const selectedArea = areas.find((a) => a.id === state.selectedArea?.id);
      return {
        ...state,
        areas,
        selectedArea,
        parkingSpots: [],
        selectedParkingSpot: undefined,
      };
    }
    case ActionType.SELECT_AREA: {
      return {
        ...state,
        selectedArea: action.payload as AreaType,
      };
    }
    case ActionType.SET_PARKING_SPOTS: {
      const parkingSpots = action.payload as ParkingType[];
      const selectedParkingSpot = parkingSpots.find(
        (p) => p.id === state.selectedParkingSpot?.id,
      );
      return {
        ...state,
        parkingSpots,
        selectedParkingSpot,
      };
    }
    case ActionType.SELECT_PARKING_SPOT: {
      return {
        ...state,
        selectedParkingSpot: action.payload as ParkingType,
      };
    }
    case ActionType.SET_UNAVAILABLE_SLOTS: {
      return {
        ...state,
        unavailableSlots: action.payload as number[],
      };
    }
    case ActionType.SET_HOURS_RANGE: {
      const [start, end] = action.payload as TimeSlot;
      return {
        ...state,
        hoursStart: start,
        hoursEnd: end,
      };
    }
    default:
      throw new Error("Unknown action");
  }
}
