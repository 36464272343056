import { useMutation } from "@apollo/client";
import { endUserAttendance } from "gql/mutations/attendance/endUserAttendance";
import { UserAttendanceType } from "api/schema";

interface GqlData {
  endUserAttendance: UserAttendanceType;
}

interface GqlVars {
  attendanceId: string;
  endTime: string;
}

export function useEndUserAttendance() {
  return useMutation<GqlData, GqlVars>(endUserAttendance)[0];
}
