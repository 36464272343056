import { useMutation } from "@apollo/client";
import { startUserAttendanceBreak } from "gql/mutations/attendance/startUserAttendanceBreak";
import { UserAttendanceType } from "api/schema";

interface GqlData {
  startUserAttendanceBreak: UserAttendanceType;
}

interface GqlVars {
  attendanceId: string;
  breakStartTime: string;
}

export function useStartUserAttendanceBreak() {
  return useMutation<GqlData, GqlVars>(startUserAttendanceBreak)[0];
}
